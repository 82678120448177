import { Translations } from '../models';

const getTranslations = <T extends Translations>(translations: T): T => translations;

export const TRANSLATIONS = getTranslations({
    'action': {
        'add': [
            'Add',
            'Add'
        ],
        'addGroup': [
            'Add a group',
            'Add a group'
        ],
        'cancel': [
            'Cancel',
            'Cancel'
        ],
        'close': [
            'Close',
            'Close'
        ],
        'compact': [
            'Compact View',
            'Compact View'
        ],
        'copy': [
            'Copy',
            'Copy'
        ],
        'copyLink': [
            'Copy Link',
            'Copy Link'
        ],
        'create': [
            'Create',
            'Create'
        ],
        'createMachine': [
            'Create Machine',
            'Create Machine'
        ],
        'createFair': [
            'Create fair',
            'Create fair'
        ],
        'delete': [
            'Delete',
            'Delete'
        ],
        'detail': [
            'Detailed View',
            'Detailed View'
        ],
        'download': [
          'Download',  
          'Download'  
        ],
        'edit': [
            'Edit',
            'Edit'
        ],
        'generateReport': [
          'Generate New Report',  
          'Generate New Report'  
        ],
        'goToCompass': [
          'Go to Machine Compass',  
          'Go to Machine Compass'
        ],
        'next': [
            'Next',
            'Next'
        ],
        'nextStep': [
            'Next step',
            'Next step'
        ],
        'no': [
            'No',
            'No'
        ],
        'open': [
            'Open',
            'Open'
        ],
        'preview': [
            'Preview',
            'Preview'
        ],
        'print': [
            'Print',
            'Print'
        ],
        'release': [
            'Release',
            'Release'
        ],
        'resendNotification': [
            'Resend Notification',  
            'Resend Notification'  
        ],
        'review': [
            'Review',
            'Review'
        ],
        'save': [
            'Save',
            'Save'
        ],
        'send': [
            'Send',
            'Send'
        ],
        'share': [
            'Share',
            'Share'
        ],
        'shareAs': [
            'Share as',
            'Share as'
        ],        
        'showMore': [
            'Show more',
            'Show more'
        ],
        'sortBy': [
            'Sort by',
            'Sort by'
        ],
        'tryDigitalTwin': [
          'Try the digital twin',  
          'Try the digital twin'  
        ],
        'yes': [
            'Yes',
            'Yes'
        ]
    },
    'category': {
        'categories': [
            'Categories',
            'Categories'
        ],
        'category': [
            'Category',
            'Category'
        ],
        'createCategoryFor': [
            'Create category for',
            'Create category for'
        ],
        'createCategoryGroupFor': [
            'Create category group for',
            'Create category group for'
        ],
        'editCategory': [
            'Edit category',
            'Edit category'
        ],
        'primary': [
            'Primary',
            'Primary'
        ],
        'secondary': [
            'Secondary',
            'Secondary'
        ],
        'subgroupRule': {
            '0': [
                'No rule',
                'No rule'
            ],
            '1': [
                'Exactly one',
                'Exactly one'
            ],
            '2': [
                'Primary/Secondary',
                'Primary/Secondary'
            ]
        }
    },
    'collection': {
        'agencyEmails': [
            'Agency emails',
            'Agency emails'
        ],
        'chiefEditorEmails': [
            'Chief editor emails',
            'Chief editor emails'
        ],
        'dateOfFirstPublish': [
            'Date of first publish',
            'Date of first publish'
        ],
        'myEBooklet': [
            'My eBooklets',
            'My eBooklets'
        ],
        'eBooklet': [
            'EBooklet',
            'EBooklet'
        ],
        'eBooklets': [
            'eBooklets',
            'eBooklets'
        ],
        'endDateOfEdit': [
            'End date of edit',
            'End date of edit'
        ],
        'endDateOfFair': [
            'End date of fair',
            'End date of fair'
        ],
        'imageDescription': [
            'Image description',
            'Image description'
        ],
        'initialDescription': [
            'Initial description',
            'Initial description'
        ],
        'machineCompass': [
            'Machine Compass',
            'Machine Compass'
        ],
        'secondDescription': [
            'Second description',
            'Second description'
        ],
        'sinumerikCompass': [
            'SINUMERIK Machine Compass',
            'SINUMERIK Machine Compass'
        ],
        'startDateOfEdit': [
            'Start date of edit',
            'Start date of edit'
        ],
        'startDateOfFair': [
            'Start date of fair',
            'Start date of fair'
        ],
        'tradeShow': [
            'Trade Show',
            'Trade Show'
        ],
        'status': {
            '0': [
                'Draft',
                'Draft'
            ],
            '1': [
                'Released for companies',
                'Released for companies'
            ],
            '2': [
                'Released for fair',
                'Released for fair'
            ],
            '3': [
                'Closed',
                'Closed'
            ]
        }
    },
    'cookie': {
        'notice': {
            '1': [
                'Status: December 2021',
                'Status: December 2021'
            ],
            '2': [
                'This Cookie Notice applies to the websites accessible under “siemens.com” (the “website”).',
                'This Cookie Notice applies to the websites accessible under “siemens.com” (the “website”).'
            ],
            '3': [
                'This website uses cookies. Cookies are small files containing certain information that are stored on your device. Cookies are served either by Siemens AG or Siemens Industry Software, Inc. (collectively “Siemens”) – so-called “first party cookies” – or by third parties providing services to Siemens; such cookies are referred to as “third party cookies”.',
                'This website uses cookies. Cookies are small files containing certain information that are stored on your device. Cookies are served either by Siemens AG or Siemens Industry Software, Inc. (collectively “Siemens”) – so-called “first party cookies” – or by third parties providing services to Siemens; such cookies are referred to as “third party cookies”.'
            ],
            '4': [
                'Cookies are necessary to provide certain functionalities on a website, e.g. to maintain a language choice made by a user. Moreover, cookies enable the respective issuer (Siemens or a third party) to recognize that the website was previously called up by a certain end device, allowing the identification of returning visitors (or rather their end devices) and gathering information about their usage of the website and their presumed interests.',
                'Cookies are necessary to provide certain functionalities on a website, e.g. to maintain a language choice made by a user. Moreover, cookies enable the respective issuer (Siemens or a third party) to recognize that the website was previously called up by a certain end device, allowing the identification of returning visitors (or rather their end devices) and gathering information about their usage of the website and their presumed interests.'
            ],
            '5': [
                'This information is used to improve the website and to deliver relevant advertisement for Siemens products and services on the website as well as on third party sites.',
                'This information is used to improve the website and to deliver relevant advertisement for Siemens products and services on the website as well as on third party sites.'
            ],
            '6': [
                'Cookies can be assigned to four categories, depending on their function and intended purpose: strictly necessary cookies, performance cookies, functionality cookies, and marketing cookies.',
                'Cookies can be assigned to four categories, depending on their function and intended purpose: strictly necessary cookies, performance cookies, functionality cookies, and marketing cookies.'
            ]

        },
        'necessaryTitle': [
            'Strictly Necessary Cookies',
            'Strictly Necessary Cookies'
        ],
        'necessaryDescription': {
            '1': [
                'These cookies are essential in order to enable you to move around the website and use its features, such as setting your privacy preferences, logging in or filling in forms. Without these cookies, services requested through usage of our website cannot be properly provided.',
                'These cookies are essential in order to enable you to move around the website and use its features, such as setting your privacy preferences, logging in or filling in forms. Without these cookies, services requested through usage of our website cannot be properly provided.'
            ],
            '2': [
                'Strictly necessary cookies do not require consent from the user under applicable law. You may configure your web browser to block strictly necessary cookies, but you might then not be able to use the website’s functionalities as intended.',
                'Strictly necessary cookies do not require consent from the user under applicable law. You may configure your web browser to block strictly necessary cookies, but you might then not be able to use the website’s functionalities as intended.'
            ],
            '3': [
                'To the extent that information processed in connection with strictly necessary cookies should qualify as personal data in a given case, the legal ground for that processing is Siemens’ legitimate interest to operate the website.',
                'To the extent that information processed in connection with strictly necessary cookies should qualify as personal data in a given case, the legal ground for that processing is Siemens’ legitimate interest to operate the website.'
            ]
        },
        'performanceTitle': [
          'Performance Cookies',  
          'Performance Cookies'  
        ],
        'performanceDescription': {
            '1':[
                'These cookies collect information about how visitors use a website, for instance which pages visitors go to most often, and how visitors move around the site. They help us to better understand a user’s behavior on our webpage. This is necessary to improve the user friendliness of a website and therefore enhance the user’s experience.',
                'These cookies collect information about how visitors use a website, for instance which pages visitors go to most often, and how visitors move around the site. They help us to better understand a user’s behavior on our webpage. This is necessary to improve the user friendliness of a website and therefore enhance the user’s experience.'
            ],
            '2':[
                'Further information collected by performance cookies may include e.g.: internet browser and operating system used, the domain name of the website which you previously visited, the number of visits, average duration of visit, and pages called up.',
                'Further information collected by performance cookies may include e.g.: internet browser and operating system used, the domain name of the website which you previously visited, the number of visits, average duration of visit, and pages called up.'
            ],
            '3':[
                'In general, the information collected by these cookies is aggregated and can normally not be linked to a specific natural person. However, in certain cases the collected information can be linked to you directly (provided you disclosed your identity to Siemens, e.g., when creating an account or filing a request with us). To the extent that information processed in connection with performance cookies can, in a given case, be directly linked to you or otherwise qualifies as personal data, the legal ground for that processing is your user consent.',
                'In general, the information collected by these cookies is aggregated and can normally not be linked to a specific natural person. However, in certain cases the collected information can be linked to you directly (provided you disclosed your identity to Siemens, e.g., when creating an account or filing a request with us). To the extent that information processed in connection with performance cookies can, in a given case, be directly linked to you or otherwise qualifies as personal data, the legal ground for that processing is your user consent.'
            ]
        },
        'functionalityTitle': [
            'Functionality Cookies',
            'Functionality Cookies'
        ],
        'functionalityDescription': {
            '1': [
                'These cookies allow the website to remember choices you make or information you enter (such as your username, language or the region you are in) and provide enhanced, more personal features. They are also used to enable requested functions such as playing videos.',
                'These cookies allow the website to remember choices you make or information you enter (such as your username, language or the region you are in) and provide enhanced, more personal features. They are also used to enable requested functions such as playing videos.'
            ],
            '2': [
                'To the extent that information processed in connection with functionality cookies should, in a given case, qualify as personal data, the legal ground for that processing is the user’s consent.',
                'To the extent that information processed in connection with functionality cookies should, in a given case, qualify as personal data, the legal ground for that processing is the user’s consent.'
            ]
        },
        'marketingTitle': [
            'Marketing Cookies',
            'Marketing Cookies'
        ],
        'marketingDescription': {
            '1': [
                'Marketing cookies (also referred to as targeting or advertising cookies) are used to deliver adverts on third party websites more relevant to you and your interests. They are also used to limit the number of times you see an advertisement as well as help measure the effectiveness of an advertising campaign.',
                'Marketing cookies (also referred to as targeting or advertising cookies) are used to deliver adverts on third party websites more relevant to you and your interests. They are also used to limit the number of times you see an advertisement as well as help measure the effectiveness of an advertising campaign.'
            ],
            '2': [
                'Legal basis for the processing of personal data in connection with marketing cookies (if any) is the user’s consent.',
                'Legal basis for the processing of personal data in connection with marketing cookies (if any) is the user’s consent.'
            ]
        },
        'noticeFinal': {
            '1': [
                'Siemens AG and Siemens Industry Software, Inc. are jointly responsible for any personal data collected by cookies as described in this Cookie Notice and have entered into an agreement setting out their respective data protection responsibilities. A summary of the content of that agreement is made available upon request.',
                'Siemens AG and Siemens Industry Software, Inc. are jointly responsible for any personal data collected by cookies as described in this Cookie Notice and have entered into an agreement setting out their respective data protection responsibilities. A summary of the content of that agreement is made available upon request.'
            ],
            '2': [
                'In case of any request in connection with this Cookie Notice, please contact the Data Privacy Organization at dataprotection@siemens.com',
                'In case of any request in connection with this Cookie Notice, please contact the Data Privacy Organization at dataprotection@siemens.com'
            ]
        }
    },
    'common': {
        'about': [
          'About',  
          'About'  
        ],
        'addedToFavourites': [
            'Added to favourites',
            'Added to favourites'
        ],
        'address': [
            'Address',
            'Address'
        ],
        'addToFavourites': [
            'Add to favourites',
            'Add to favourites'
        ],
        'advancedFilters': [
            'Advanced filters',
            'Advanced filters'
        ],
        'allFilters': [
            'All filters',
            'All filters'
        ],
        'approve': [
            'Approve',
            'Approve'
        ],
        'approvingCustomer': [
            'Customer Approval E-mail',
            'Customer Approval E-mail'
        ],
        'basicFilters': [
            'Filters',
            'Filters'
        ],
        'chiefEditorRequests': [
            'Chief Editor Requests',
            'Chief Editor Requests'
        ],
        'city': [
            'City',
            'City'
        ],
        'color': [
            'Color',
            'Color'
        ],
        'comment': [
            'Comment',
            'Comment'
        ],
        'commentTitle': [
            'This request will be forwarded to your',
            'This request will be forwarded to your'
        ],
        'countries': [
            'Countries',
            'Countries'
        ],
        'country': [
            'Country',
            'Country'
        ],
        'createAndReleaseEntry': [
            'Create and release entry',
            'Create and release entry'
        ],
        'createEntry': [
            'Create entry',
            'Create entry'
        ],
        'date': [
            'Date',
            'Date'
        ],
        'description': [
            'Description of machine',
            'Description of machine'
        ],
        'descriptionMachine': [
            'About the machine',
            'About the machine'
        ],
        'descriptionCNC': [
            'Benefits of SINUMERIK',
            'Benefits of SINUMERIK'
        ],
        'descriptions': [
            'Descriptions',
            'Descriptions'
        ],
        'editEntry': [
            'Edit entry',
            'Edit entry'
        ],
        'enableDigitalTwin': [
            'Enable Digital Twin?',
            'Enable Digital Twin?'
        ],
        'email': [
            'Email',
            'Email'
        ],
        'excelLastGeneration': [
          'Last generated on:',  
          'Last generated on:'  
        ],
        'excelError': [
          'Could not generate report, please make sure you are not on an Incognito tab.',  
          'Could not generate report, please make sure you are not on an Incognito tab.'  
        ],
        'excelExport': [
            'Export Excel overview of machines',
            'Export Excel overview of machines'
        ],
        'fair': [
            'Fair',  
            'Fair'  
        ],        
        'fairs': [
            'Fairs',  
            'Fairs'  
        ],
        'fax': [
            'Fax',
            'Fax'
        ],
        'filter': [
            'Filter',
            'Filter'
        ],
        'general': [
            'General',
            'General'
        ],
        'gid': [
            'GID',
            'GID'
        ],
        'image': [
            'Image',
            'Image'
        ],
        'info': [
            'Info',
            'Info'
        ],
        'landingPageSnackbar': [
          'Are you looking to purchase a new machine tool or are you simply planning your next visit to a trade show? SMC is your guide through the offer of new machine equipped with SINUMERIK CNC.',
          'Are you looking to purchase a new machine tool or are you simply planning your next visit to a trade show? SMC is your guide through the offer of new machine equipped with SINUMERIK CNC.'
        ],
        'landingPageTitle': [
          'Discover the world of SINUMERIK machines',
          'Discover the world of SINUMERIK machines'
        ],
        'location': [
            'Location',
            'Location'
        ],
        'manufacturers': [
            'Manufacturers',
            'Manufacturers'
        ],
        'message': [
            'Message',
            'Message'
        ],
        'myFavourites': [
            'My favourites',
            'My favourites'
        ],
        'name': [
            'Name',
            'Name'
        ],
        'newFair': [
            'New fair',
            'New fair'
        ],
        'noComment': [
            'No release comment available',
            'No release comment available'
        ],
        'noPermission': [
            'No permission',
            'No permission'
        ],
        'obsolete': [
            'Obsolete',
            'Obsolete'
        ],        
        'optional': [
            'Optional',
            'Optional'
        ],        
        'outdated': [
            'Outdated',
            'Outdated'
        ],
        'picture': [
            'Picture',
            'Picture'
        ],
        'phone': [
            'Phone',
            'Phone'
        ],
        'publishingAt': [
            'Publishing at',
            'Publishing at'
        ],
        'recommended': [
            'Recommended',
            'Recommended'
        ],
        'releaseEntry': [
            'Release entry',
            'Release entry'
        ],
        'requests': [
            'Requests',
            'Requests'
        ],
        'resetFilters': [
            'Reset filters',
            'Reset filters'
        ],
        'review': [
            'Review',
            'Review'
        ],
        'reject': [
            'Reject',
            'Reject'
        ],
        'roles': [
            'Roles',
            'Roles'
        ],
        'search': [
            'Search',
            'Search'
        ],
        'searchForMachineName': [
            'Search for machine name or manufacturer',
            'Search for machine name or manufacturer'
        ],
        'showResults': [
            'Show results',
            'Show results'
        ],
        'siemensRepresentative': [
            'Siemens representative',
            'Siemens representative'
        ],
        'status': [
            'Status',
            'Status'
        ],
        'street1': [
            'Street 1',
            'Street 1'
        ],
        'street2': [
            'Street 2',
            'Street 2'
        ],
        'subtitle': [
            'Subtitle',
            'Subtitle'
        ],
        'tag': [
            'Tag',
            'Tag'
        ],
        'tags': [
            'Tags',
            'Tags'
        ],
        'title': [
            'Title',
            'Title'
        ],
        'userName': [
            'Username',
            'Username'
        ],
        'web': [
            'Web',
            'Web'
        ],
        'zip': [
            'ZIP',
            'ZIP'
        ]
    },
    'company': {
        'company': [
            'Company',
            'Company'
        ],
        'companyName': [
            'Company Name',
            'Company Name'
        ],
        'createCompany': [
            'Create Company',
            'Create Company'
        ],
        'editCompany': [
            'Edit Company',
            'Edit Company'
        ],
        'noCompany': [
            'No company is defined',
            'No company is defined'
        ],
        'newCompany': [
            'New Company',
            'New Company'
        ],
        'siemensRepresentativeEmails': [
            'Your Siemens Contact Email(s)',
            'Your Siemens Contact Email(s)'
        ]
    },
    'countries': {
        'af': [
            'Afghanistan',
            'Afghanestan'
        ],
        'ax': [
            'Aland Islands',
            'Alandinseln'
        ],
        'al': [
            'Albania',
            'Albanien'
        ],
        'dz': [
            'Algeria',
            'Algerien'
        ],
        'as': [
            'American Samoa',
            'Amerikanisch-Samoa'
        ],
        'ad': [
            'Andorra',
            'Andorra'
        ],
        'ao': [
            'Angola',
            'Angola'
        ],
        'ai': [
            'Anguilla',
            'Anguilla'
        ],
        'ag': [
            'Antigua and Barbuda',
            'Antigua und Barbuda'
        ],
        'ar': [
            'Argentina',
            'Argentinien'
        ],
        'am': [
            'Armenia',
            'Armenien'
        ],
        'aw': [
            'Aruba',
            'Aruba'
        ],
        'au': [
            'Australia',
            'Australien'
        ],
        'at': [
            'Austria',
            'Österreich'
        ],
        'az': [
            'Azerbaijan',
            'Aserbaidschan'
        ],
        'bs': [
            'Bahamas',
            'Bahamas'
        ],
        'bh': [
            'Bahrain',
            'Bahrain'
        ],
        'bd': [
            'Bangladesh',
            'Bangladesch'
        ],
        'bb': [
            'Barbados',
            'Barbados'
        ],
        'by': [
            'Belarus',
            'Weißrussland'
        ],
        'be': [
            'Belgium',
            'Belgien'
        ],
        'bz': [
            'Belize',
            'Belize'
        ],
        'bj': [
            'Benin',
            'Benin'
        ],
        'bm': [
            'Bermuda',
            'Bermuda'
        ],
        'bt': [
            'Bhutan',
            'Bhutan'
        ],
        'bo': [
            'Bolivia',
            'Bolivien'
        ],
        'ba': [
            'Bosnia and Herzegovina',
            'Bosnien und Herzegowina'
        ],
        'bw': [
            'Botswana',
            'Botsuana'
        ],
        'br': [
            'Brazil',
            'Brasilien'
        ],
        'io': [
            'British Indian Ocean Territory',
            'Britisches Territorium im Indischen Ozean'
        ],
        'bn': [
            'Brunei Darussalam',
            'Brunei'
        ],
        'bg': [
            'Bulgaria',
            'Bulgarien'
        ],
        'bf': [
            'Burkina Faso',
            'Burkina Faso'
        ],
        'bi': [
            'Burundi',
            'Burundi'
        ],
        'kh': [
            'Cambodia',
            'Kambodscha'
        ],
        'cm': [
            'Cameroon',
            'Kamerun'
        ],
        'ca': [
            'Canada',
            'Kanada'
        ],
        'cv': [
            'Cape Verde',
            'Kap Verde'
        ],
        'ky': [
            'Cayman Islands',
            'Caymaninseln'
        ],
        'cf': [
            'Central African Republic',
            'Zentralafrikanische Republik'
        ],
        'td': [
            'Chad',
            'Tschad'
        ],
        'cl': [
            'Chile',
            'Chile'
        ],
        'cn': [
            'China',
            'China'
        ],
        'cx': [
            'Christmas Island',
            'Weihnachtsinsel'
        ],
        'cc': [
            'Cocos (Keeling) Islands',
            'Kokosinseln'
        ],
        'co': [
            'Colombia',
            'Kolumbien'
        ],
        'km': [
            'Comoros',
            'Komoren'
        ],
        'cg': [
            'Congo',
            'Kongo'
        ],
        'ck': [
            'Cook Islands',
            'Cookinseln'
        ],
        'cr': [
            'Costa Rica',
            'Costa Rica'
        ],
        'ci': [
            'Cote D\'Ivoire',
            'Elfenbeinküste'
        ],
        'hr': [
            'Croatia',
            'Kroatien'
        ],
        'cu': [
            'Cuba',
            'Kuba'
        ],
        'cy': [
            'Cyprus',
            'Zypern'
        ],
        'cz': [
            'Czechia',
            'Tschechien'
        ],
        'dk': [
            'Denmark',
            'Dänemark'
        ],
        'dj': [
            'Djibouti',
            'Dschibuti'
        ],
        'dm': [
            'Dominica',
            'Dominica'
        ],
        'do': [
            'Dominican Republic',
            'Dominikanische Republik'
        ],
        'ec': [
            'Ecuador',
            'Ecuador'
        ],
        'eg': [
            'Egypt',
            'Ägypten'
        ],
        'sv': [
            'El Salvador',
            'El Salvador'
        ],
        'gq': [
            'Equatorial Guinea',
            'Äquatorialguinea'
        ],
        'er': [
            'Eritrea',
            'Eritrea'
        ],
        'ee': [
            'Estonia',
            'Estland'
        ],
        'et': [
            'Ethiopia',
            'Äthiopien'
        ],
        'fk': [
            'Falkland Islands (Malvinas)',
            'Falklandinseln'
        ],
        'fo': [
            'Faroe Islands',
            'Färöer'
        ],
        'fj': [
            'Fiji',
            'Fidschi'
        ],
        'fi': [
            'Finland',
            'Finnland'
        ],
        'fr': [
            'France',
            'Frankreich'
        ],
        'pf': [
            'French Polynesia',
            'Französisch-Polynesien'
        ],
        'tf': [
            'French Southern Territories',
            'Französische Süd- und Antarktisgebiete'
        ],
        'ga': [
            'Gabon',
            'Gabun'
        ],
        'gm': [
            'Gambia',
            'Gambia'
        ],
        'ge': [
            'Georgia',
            'Georgien'
        ],
        'de': [
            'Germany',
            'Deutschland'
        ],
        'gh': [
            'Ghana',
            'Ghana'
        ],
        'gi': [
            'Gibraltar',
            'Gibraltar'
        ],
        'gr': [
            'Greece',
            'Griechenland'
        ],
        'gl': [
            'Greenland',
            'Grönland'
        ],
        'gd': [
            'Grenada',
            'Grenada'
        ],
        'gp': [
            'Guadeloupe',
            'Guadeloupe'
        ],
        'gu': [
            'Guam',
            'Guam'
        ],
        'gt': [
            'Guatemala',
            'Guatemala'
        ],
        'gn': [
            'Guinea',
            'Guinea'
        ],
        'gw': [
            'Guinea-Bissau',
            'Guinea-Bissau'
        ],
        'gy': [
            'Guyana',
            'Guyana'
        ],
        'ht': [
            'Haiti',
            'Haiti'
        ],
        'va': [
            'Holy See (Vatican City State)',
            'Vatikanstadt'
        ],
        'hn': [
            'Honduras',
            'Honduras'
        ],
        'hk': [
            'Hong Kong',
            'Hong Kong'
        ],
        'hu': [
            'Hungary',
            'Ungarn'
        ],
        'is': [
            'Iceland',
            'Island'
        ],
        'in': [
            'India',
            'Indien'
        ],
        'id': [
            'Indonesia',
            'Indonesien'
        ],
        'ir': [
            'Iran',
            'Iran'
        ],
        'iq': [
            'Iraq',
            'Irak'
        ],
        'ie': [
            'Ireland',
            'Irland'
        ],
        'il': [
            'Israel',
            'Israel'
        ],
        'it': [
            'Italy',
            'Italien'
        ],
        'jm': [
            'Jamaica',
            'Jamaica'
        ],
        'jp': [
            'Japan',
            'Japan'
        ],
        'jo': [
            'Jordan',
            'Jordanien'
        ],
        'kz': [
            'Kazakhstan',
            'Kasachstan'
        ],
        'ke': [
            'Kenya',
            'Kenia'
        ],
        'ki': [
            'Kiribati',
            'Kiribati'
        ],
        'kw': [
            'Kuwait',
            'Kuwait'
        ],
        'kg': [
            'Kyrgyzstan',
            'Kirgisistan'
        ],
        'la': [
            'Laos',
            'Laos'
        ],
        'lv': [
            'Latvia',
            'Lettland'
        ],
        'lb': [
            'Lebanon',
            'Libanon'
        ],
        'ls': [
            'Lesotho',
            'Lesotho'
        ],
        'lr': [
            'Liberia',
            'Liberia'
        ],
        'ly': [
            'Libyan Arab Jamahiriya',
            'Libyen'
        ],
        'li': [
            'Liechtenstein',
            'Liechtenstein'
        ],
        'lt': [
            'Lithuania',
            'Litauen'
        ],
        'lu': [
            'Luxembourg',
            'Luxemburg'
        ],
        'mo': [
            'Macao',
            'Macau'
        ],
        'mk': [
            'Macedonia',
            'Nordmazedonien'
        ],
        'mg': [
            'Madagascar',
            'Madagaskar'
        ],
        'mw': [
            'Malawi',
            'Malawi'
        ],
        'my': [
            'Malaysia',
            'Malaysia'
        ],
        'mv': [
            'Maldives',
            'Malediven'
        ],
        'ml': [
            'Mali',
            'Mali'
        ],
        'mt': [
            'Malta',
            'Malta'
        ],
        'mh': [
            'Marshall Islands',
            'Marshallinseln'
        ],
        'mq': [
            'Martinique',
            'Martinique'
        ],
        'mr': [
            'Mauritania',
            'Mauritanien'
        ],
        'mu': [
            'Mauritius',
            'Mauritius'
        ],
        'yt': [
            'Mayotte',
            'Mayotte'
        ],
        'mx': [
            'Mexico',
            'Mexico'
        ],
        'fm': [
            'Micronesia',
            'Mikronesien'
        ],
        'md': [
            'Moldova',
            'Moldavien'
        ],
        'mc': [
            'Monaco',
            'Monaco'
        ],
        'mn': [
            'Mongolia',
            'Mongolei'
        ],
        'me': [
            'Montenegro',
            'Montenegro'
        ],
        'ms': [
            'Montserrat',
            'Montserrat'
        ],
        'ma': [
            'Morocco',
            'Marokko'
        ],
        'mz': [
            'Mozambique',
            'Moçambique'
        ],
        'mm': [
            'Myanmar',
            'Myanmar'
        ],
        'na': [
            'Namibia',
            'Namibia'
        ],
        'nr': [
            'Nauru',
            'Nauru'
        ],
        'np': [
            'Nepal',
            'Nepal'
        ],
        'nl': [
            'Netherlands',
            'Niederlande'
        ],
        'an': [
            'Netherlands Antilles',
            'Antyle Holenderskie'
        ],
        'nc': [
            'New Caledonia',
            'Neukaledonien'
        ],
        'nz': [
            'New Zealand',
            'Neuseeland'
        ],
        'ni': [
            'Nicaragua',
            'Nicaragua'
        ],
        'ne': [
            'Niger',
            'Niger'
        ],
        'ng': [
            'Nigeria',
            'Nigeria'
        ],
        'nu': [
            'Niue',
            'Niue'
        ],
        'nf': [
            'Norfolk Island',
            'Norfolkinsel'
        ],
        'mp': [
            'Northern Mariana Islands',
            'Nördliche Marianen'
        ],
        'no': [
            'Norway',
            'Norwegen'
        ],
        'om': [
            'Oman',
            'Oman'
        ],
        'pk': [
            'Pakistan',
            'Pakistan'
        ],
        'pw': [
            'Palau',
            'Palau'
        ],
        'ps': [
            'Palestine',
            'Palästina'
        ],
        'pa': [
            'Panama',
            'Panama'
        ],
        'pg': [
            'Papua New Guinea',
            'Papua-Neuguinea'
        ],
        'py': [
            'Paraguay',
            'Paraguay'
        ],
        'pe': [
            'Peru',
            'Peru'
        ],
        'ph': [
            'Philippines',
            'Philippinen'
        ],
        'pn': [
            'Pitcairn',
            'Pitcairn'
        ],
        'pl': [
            'Poland',
            'Polen'
        ],
        'pt': [
            'Portugal',
            'Portugal'
        ],
        'pr': [
            'Puerto Rico',
            'Puerto Rico'
        ],
        'qa': [
            'Qatar',
            'Katar'
        ],
        'ro': [
            'Romania',
            'Rumänien'
        ],
        'ru': [
            'Russian Federation',
            'Russland'
        ],
        'rw': [
            'Rwanda',
            'Ruanda'
        ],
        'sh': [
            'Saint Helena',
            'Sankt Helena'
        ],
        'kn': [
            'Saint Kitts and Nevis',
            'St. Kitts und Nevis'
        ],
        'lc': [
            'Saint Lucia',
            'Saint Lucia'
        ],
        'pm': [
            'Saint Pierre and Miquelon',
            'Sankt Pierre und Miquelon'
        ],
        'vc': [
            'Saint Vincent and the Grenadines',
            'St. Vincent und die Grenadinen'
        ],
        'ws': [
            'Samoa',
            'Samoa'
        ],
        'sm': [
            'San Marino',
            'San Marino'
        ],
        'st': [
            'Sao Tome and Principe',
            'São Tomé und Príncipe'
        ],
        'sa': [
            'Saudi Arabia',
            'Saudi-Arabien'
        ],
        'sn': [
            'Senegal',
            'Senegal'
        ],
        'rs': [
            'Serbia',
            'Serbien'
        ],
        'sc': [
            'Seychelles',
            'Seychellen'
        ],
        'sl': [
            'Sierra Leone',
            'Sierra Leone'
        ],
        'sg': [
            'Singapore',
            'Singapur'
        ],
        'sk': [
            'Slovakia',
            'Slowakei'
        ],
        'si': [
            'Slovenia',
            'Slovenien'
        ],
        'sb': [
            'Solomon Islands',
            'Salomonen'
        ],
        'so': [
            'Somalia',
            'Somalia'
        ],
        'za': [
            'South Africa',
            'Südafrika'
        ],
        'gs': [
            'South Georgia',
            'Südgeorgien '
        ],
        'kr': [
            'South Korea',
            'Südkorea'
        ],
        'es': [
            'Spain',
            'Spanien'
        ],
        'lk': [
            'Sri Lanka',
            'Sri Lanka'
        ],
        'sd': [
            'Sudan',
            'Sudan'
        ],
        'sr': [
            'Suriname',
            'Suriname'
        ],
        'sz': [
            'Swaziland',
            'Swasiland'
        ],
        'se': [
            'Sweden',
            'Schweden'
        ],
        'ch': [
            'Switzerland',
            'Schweiz'
        ],
        'sy': [
            'Syrian Arab Republic',
            'Syrien'
        ],
        'tw': [
            'Taiwan',
            'Taiwan'
        ],
        'tj': [
            'Tajikistan',
            'Tadschikistan'
        ],
        'tz': [
            'Tanzania',
            'Tansania'
        ],
        'th': [
            'Thailand',
            'Thailand'
        ],
        'tl': [
            'Timor-Leste',
            'Osttimor'
        ],
        'tg': [
            'Togo',
            'Togo'
        ],
        'tk': [
            'Tokelau',
            'Tokelau'
        ],
        'to': [
            'Tonga',
            'Tonga'
        ],
        'tt': [
            'Trinidad and Tobago',
            'Trinidad und Tobago'
        ],
        'tn': [
            'Tunisia',
            'Tunesien'
        ],
        'tr': [
            'Turkey',
            'Türkei'
        ],
        'tm': [
            'Turkmenistan',
            'Turkmenistan'
        ],
        'tc': [
            'Turks and Caicos Islands',
            'Turks- und Caicosinseln'
        ],
        'tv': [
            'Tuvalu',
            'Tuvalu'
        ],
        'ug': [
            'Uganda',
            'Uganda'
        ],
        'ua': [
            'Ukraine',
            'Ukraine'
        ],
        'ae': [
            'United Arab Emirates',
            'Vereinigte Arabische Emirate'
        ],
        'gb': [
            'United Kingdom',
            'Vereinigtes Königreich'
        ],
        'us': [
            'United States',
            'Vereinigte Staaten'
        ],
        'um': [
            'United States Minor Outlying Islands',
            'Kleinere abgelegene Inseln der Vereinigten Staaten'
        ],
        'uy': [
            'Uruguay',
            'Uruguay'
        ],
        'uz': [
            'Uzbekistan',
            'Usbekistan'
        ],
        'vu': [
            'Vanuatu',
            'Vanuatu'
        ],
        've': [
            'Venezuela',
            'Venezuela'
        ],
        'vn': [
            'Vietnam',
            'Vietnam'
        ],
        'vg': [
            'Virgin Islands, British',
            'Britische Jungferninseln'
        ],
        'vi': [
            'Virgin Islands, U.S.',
            'Amerikanische Jungferninseln'
        ],
        'wf': [
            'Wallis and Futuna',
            'Wallis und Futuna'
        ],
        'eh': [
            'Western Sahara',
            'Westsahara'
        ],
        'ye': [
            'Yemen',
            'Jemen'
        ],
        'zm': [
            'Zambia',
            'Sambia'
        ],
        'zw': [
            'Zimbabwe',
            'Simbabwe'
        ]
    },
    'dialog': {
        'agreeTerms': [
            'I have read and agree to the above terms and conditions. I have already reviewed the machine article.',
            'I have read and agree to the above terms and conditions. I have already reviewed the machine article.'
        ],
        'choseEBooklet': [
            'Choose an eBooklet',
            'Choose an eBooklet'
        ],
        'choseUser': [
            'Choose a User',
            'Choose a User'
        ],
        'confirmDelete': [
            'Are you sure you want to delete this item?',
            'Are you sure you want to delete this item?'
        ],        
        'confirmEdit': [
            'Are you sure you want to edit this item? (This will cause a new approval process to be started once you are done)',
            'Are you sure you want to edit this item? (This will cause a new approval process to be started once you are done)'
        ],
        'confirmReject': [
            'Are you sure you want to reject this entry?',
            'Are you sure you want to reject this entry?'
        ],
        'confirmRelease': [
            'Are you sure you want to release this item?',
            'Are you sure you want to release this item?'
        ],
        'copiedLink': [
            'Link copied to clipboard!',
            'Link copied to clipboard!'
        ],
        'createTarget': [
            'Create your machine for one of the following target systems:',
            'Create your machine for one of the following target systems:'
        ],
        'delegateTranslation': [
            'Delegate the translations to Siemens based on the created languages',
            'Delegate the translations to Siemens based on the created languages'
        ],
        'dontPublishUntil': [
            'Don\'t publish until start of fair',
            'Don\'t publish until start of fair'
        ],
        'draft': [
            'No booklet, create as draft',
            'No booklet, create as draft'
        ],
        'dropText': [
            'Drag & drop files here or click to upload',
            'Drag & drop files here or click to upload'
        ],
        'fairExhibited': [
            'Exhibited at fair(s)',
            'Exhibited at fair(s)'
        ],        
        'fairSelect': [
            'Select fair(s)',
            'Select fair(s)'
        ],
        'fillMandatory': [
            'Please fill all mandatory fields',
            'Please fill all mandatory fields'
        ],
        'forReleaseIn': [
            'for Release in',
            'for Release in'
        ],
        'imageFormat': [
            'file format JPG or PNG, min resolution: 800x600, recommended max resolution: 2500x2500, max file size: 4Mb',
            'file format JPG or PNG, min resolution: 800x600, recommended max resolution: 2500x2500, max file size: 4Mb'
        ],
        'incompleteVisible': [
            'Incomplete version of this machine is visible for my Siemens contact and the Chief Editor',
            'Incomplete version of this machine is visible for my Siemens contact and the Chief Editor'
        ],
        'initialBanner': [
            'This application is an internal demo, the used content and images are intended for internal use only',
            'This application is an internal demo, the used content and images are intended for internal use only'
        ],
        'legalForRep': [
            'The legal text above is only for your information and will be sent to the customer for approval',
            'The legal text above is only for your information and will be sent to the customer for approval'
        ],
        'noRights': [
            'Do not have the proper rights to visit the page.',
            'Do not have the proper rights to visit the page.'
        ],
        'notSelected': [
            'Not selected',
            'Not selected'
        ],
        'publishAt': [
            'Publish at',
            'Publish at'
        ],
        'releaseQuestion': [
            'Do you want to release your machine to one of the following target system?',
            'Do you want to release your machine to one of the following target system?'
        ],
        'releaseTarget': [
            'Release your machine for one of the following target systems:',
            'Release your machine for one of the following target systems:'
        ],
        'resolutionChange': [
            'Image exceeded recommended max resolution, it will be adjusted once the machine is saved/released.',
            'Image exceeded recommended max resolution, it will be adjusted once the machine is saved/released.'
        ],
        'saveItem': [
            'Do you want to save the item?',
            'Do you want to save the item?'
        ],
        'select': [
            'Select an item',
            'Select an item'
        ],
        'selectType': [
            'Select type:',
            'Select type:'
        ],
        'showAllDetails': [
            'show all details',
            'show all details'
        ],
        'showResultsAndFilter': [
            'Show results & filter',
            'Show results & filter'
        ],
        'successfulCopy': [
            'Successfully copied!',
            'Successfully copied!'
        ],
        'successfulDelete': [
            'Successfully deleted!',
            'Successfully deleted!'
        ],
        'successfulEmail': [
            'Reminder Email successfully sent!',
            'Reminder Email successfully sent!'
        ],
        'successfulReject': [
            'Successfully rejected!',
            'Successfully rejected!'
        ],
        'successfulRelease': [
            'Successful release!',
            'Successful release!'
        ],
        'successfulSave': [
            'Successfully saved!',
            'Successfully saved!'
        ],
            'termsAndConditions': {
                '1': [
                    'I hereby agree that Siemens AG, having its registered seat in Berlin and Munich, Germany, and its affiliated companies or third parties on their behalf, may use or edit the works provided by me, i.e. photographs, illustrations and texts within the SINUMERIK Machine Compass, in unrevised, revised or edited form, in whole or in part, in particular for the purpose of information, advertising, and other publication, including commercial purposes, in any way or form and via any communication medium, including on-line and in Social Media Platforms use based on individual demand or otherwise, regardless of the type of receiver.',
                    'I hereby agree that Siemens AG, having its registered seat in Berlin and Munich, Germany, and its affiliated companies or third parties on their behalf, may use or edit the works provided by me, i.e. photographs, illustrations and texts within the SINUMERIK Machine Compass, in unrevised, revised or edited form, in whole or in part, in particular for the purpose of information, advertising, and other publication, including commercial purposes, in any way or form and via any communication medium, including on-line and in Social Media Platforms use based on individual demand or otherwise, regardless of the type of receiver.'
                ],
                '2': [
                    'This right to use is perpetual, world-wide and unrestricted in number and content and includes in particular',
                    'This right to use is perpetual, world-wide and unrestricted in number and content and includes in particular'
                ],
                '3': [
                    '- copying, distribution and publication using any communication medium regardless of the form used,',
                    '- copying, distribution and publication using any communication medium regardless of the form used,'
                ],
                '4': [
                    '- public presentation and',
                    '- public presentation and'
                ],
                '5': [
                    '- granting the right to third parties to use any works in all forms mentioned above within the SINUMERIK Machine Compass.',
                    '- granting the right to third parties to use any works in all forms mentioned above within the SINUMERIK Machine Compass.'
                ],
                '6': [
                    'Siemens reserves the right not to publish certain articles, especially if only a few Siemens components are installed.',
                    'Siemens reserves the right not to publish certain articles, especially if only a few Siemens components are installed.'
                ],
                '7': [
                    'I expressly waive any right to authorship credit with respect to the works set out above. I explicitly waive any right to remuneration or any other rights regarding the works or copies thereof. Information as to the processing of personal data can be found at www.siemens.com/privacy. This declaration shall be governed by German law, excluding the provisions of the UN Convention of Contracts for the International Sale of Goods.',
                    'I expressly waive any right to authorship credit with respect to the works set out above. I explicitly waive any right to remuneration or any other rights regarding the works or copies thereof. Information as to the processing of personal data can be found at www.siemens.com/privacy. This declaration shall be governed by German law, excluding the provisions of the UN Convention of Contracts for the International Sale of Goods.'
                ]
            }
    },
    'error': {
        'categoryDelete': [
            'Cannot delete category!',
            'Cannot delete category!'
        ],
        'categoryLoad': [
            'Cannot load category!',
            'Cannot load category!'
        ],
        'categorySave': [
            'Cannot save category!',
            'Cannot save category!'
        ],
        'chipDeleteNoResult': [
            'Delete would cause no result',
            'Delete would cause no result'
        ],
        'collectionDelete': [
            'Cannot delete collection!',
            'Cannot delete collection!'
        ],
        'collectionLoad': [
            'Cannot load collection!',
            'Cannot load collection!'
        ],
        'collectionRelease': [
            'Cannot release collection!',
            'Cannot release collection!'
        ],
        'collectionSave': [
            'Cannot save collection!',
            'Cannot save collection!'
        ],
        'collectionsLoad': [
            'Cannot load collections!',
            'Cannot load collections!'
        ],
        'companiesLoad': [
            'Cannot load companies!',
            'Cannot load companies!'
        ],
        'companyLoad': [
            'Cannot load company!',
            'Cannot load company!'
        ],
        'companySave': [
            'Cannot save company!',
            'Cannot save company!'
        ],
        'dateStartBeforeEnd': [
            'Start date must be before the End date',
            'Start date must be before the End date'
        ],
        'dateStartAfterToday': [
            'Start date must be from today or later',
            'Start date must be from today or later'
        ],
        'dateEndAfterStart': [
            'End date must be later than the Start date',
            'End date must be later than the Start date'
        ],
        'duplication': [
            'Cannot add duplicated value',
            'Cannot add duplicated value'
        ],
        'emailSend': [
          'Could not send reminder email!', 
          'Could not send reminder email!' 
        ],
        'fairDateEndAfterStart': [
            'End date of fair must be after the start date of fair',
            'End date of fair must be after the start date of fair'
        ],
        'fairDateFirstPublishAfterEndDate': [
            'Date of first publish must be after end date of edit',
            'Date of first publish must be after end date of edit'
        ],
        'fairDateStartAfterFirstPublish': [
            'Start date of fair must be after or on the date of first publish',
            'Start date of fair must be after or on the date of first publish'
        ],
        'fileBig': [
            'File size must not exceed 4Mb',
            'File size must not exceed 4Mb'
        ],
        'fileLowResolution': [
            'File resolution must be at least 800x600px, but below 2500x2500px',
            'File resolution must be at least 800x600px, but below 2500x2500px'
        ],
        'fileType': [
            'File type not permitted',
            'File type not permitted'
        ],
        'favouriteDelete': [
            'Could not delete from favourite',
            'Could not delete from favourite'
        ],
        'favouritesLoad': [
            'Could not load favourites',
            'Could not load favourites'
        ],
        'favouriteSave': [
            'Could not save favourite',
            'Could not save favourite'
        ],
        'filterLoad': [
            'Could not load filters',
            'Could not load filters'
        ],
        'imageSave': [
            'Cannot save images!',
            'Cannot save images!'
        ],
        'invalidFormat': [
            'Invalid format',
            'Invalid format'
        ],
        'preReleaseEnd': [
            'Pre-release period is ending at {date}',
            'Pre-release period is ending at {date}'
        ],
        'preReleaseStart': [
            'Pre-release period is starting at {date}',
            'Pre-release period is starting at {date}'
        ],
        'productCopy': [
            'Cannot copy product!',
            'Cannot copy product!'
        ],
        'productDelete': [
            'Cannot delete product!',
            'Cannot delete product!'
        ],
        'productLoad': [
            'Cannot load product!',
            'Cannot load product!'
        ],
        'productNotAvailable': [
            'Product not available or doesn\'t exist!',
            'Product not available or doesn\'t exist!'
        ],
        'productRelease': [
            'Cannot release product!',
            'Cannot release product!'
        ],
        'productReviewSave': [
            'Cannot save product review!',
            'Cannot save product review!'
        ],
        'productSave': [
            'Cannot save product!',
            'Cannot save product!'
        ],
        'productUpdate': [
            'Cannot update product!',
            'Cannot update product!'
        ],
        'productsLoad': [
            'Cannot load products!',
            'Cannot load products!'
        ],
        'releasedCollectionsLoad': [
            'Cannot load released collections!',
            'Cannot load released collections!'
        ],
        'requestsLoad': [
            'Cannot load requests!',
            'Cannot load requests!'
        ],
        'tagLoad': [
            'Cannot load tag!',
            'Cannot load tag!'
        ],
        'tagsLoad': [
            'Cannot load tags!',
            'Cannot load tags!'
        ],
        'tagDelete': [
            'Cannot delete tag!',
            'Cannot delete tag!'
        ],
        'tagSave': [
            'Cannot save tag!',
            'Cannot save tag!'
        ],
        'tooLong': [
            'Maximum length exceeded',
            'Maximum length exceeded'
        ],
        'userSave': [
            'Cannot save user!',
            'Cannot save user!'
        ],
        'usersLoad': [
            'Cannot load users!',
            'Cannot load users!'
        ]
    },
    'hall': {
        'createHall': [
            'Create hall',
            'Create hall'
        ],
        'editHall': [
            'Edit hall',
            'Edit hall'
        ],
        'hall': [
            'Hall',
            'Hall'
        ],
        'halls': [
            'Halls',
            'Halls'
        ]
    },
    'language': {
        'availableLanguages': [
            'Available Languages',
            'Available Languages'
        ],
        'de': [
            'German',
            'German'
        ],
        'en': [
            'English',
            'English'
        ],
        'es': [
            'Spanish',
            'Spanish'
        ],
        'gr': [
            'Greek',
            'Greek'
        ],
        'it': [
            'Italian',
            'Italian'
        ],
        'language': [
            'Language',
            'Language'
        ],
        'languages': [
            'Languages',
            'Languages'
        ]
    },
    'navMenu': {
        'support': {
            'label': [
                'Support',
                'Unterstützung'
            ],
            'report': [
                'Report a problem',
                'Ein Problem melden'
            ]
        },
        'language': {
            'label': [
                'Language',
                'Sprache'
            ],
            'selectLanguage': [
                'Please select a language.',
                'Bitte wähle eine Sprache.'
            ],
            'en': [
                'English',
                'Englisch'
            ],
            'de': [
                'German',
                'Deutsch'
            ]
        }
    },
    'product': {
        'booth': [
            'Booth',
            'Booth'
        ],
        'creationDate': [
            'Creation date',
            'Creation date'
        ],
        'customerMachinesInCreation': [
            'Customer Machines in Creation',
            'Customer Machines in Creation'
        ],
        'customerMachinesInReview': [
            'Customer Machines in Review',
            'Customer Machines in Review'
        ],
        'customReleaseDate': [
            'Pre-release date',
            'Pre-release date'
        ],
        'lastChangeDate': [
            'Last change date',
            'Last change date'
        ],
        'machineName': [
            'Machine Name',
            'Machine Name'
        ],
        'machineTypes': [
            'Machine Types',
            'Machine Types'
        ],
        'myMachines': [
            'My Machines',
            'My Machines'
        ],
        'newMachine': [
            'New Machine',
            'New Machine'
        ],
        'products': [
            'Products',
            'Products'
        ],
        'releaseDate': [
            'Released date',
            'Released date'
        ],
        'reviewedMachines': [
            'Reviewed Machines',
            'Reviewed Machines'
        ],
        'status': {
            '0': [
                'Draft',
                'Draft'
            ],
            '1': [
                'In Review',
                'In Review'
            ],
            '3': [
                'In Review',
                'In Review'
            ],
            '4': [
                'Rejected',
                'Rejected'
            ],
            '5': [
                'Incomplete (Draft)',
                'Incomplete (Draft)'
            ],
            '6': [
                'Incomplete',
                'Incomplete'
            ],
            '7': [
                'Approved',
                'Approved'
            ],
            '8': [
                'Rejected',
                'Rejected'
            ],
            '9': [
                'In Approval',
                'In Approval'
            ],
            '10': [
                'Rejected',
                'Rejected'
            ]
        }
    },
    'user': {
        'editUser': [
            'Edit user',
            'Edit user'
        ],
        'users': [
            'Users',
            'Users'
        ]
    },
    'footer': {
        'contactUs': [
            'Contact us',
            'Kontakt'
        ],
        'contactUsLink': [
            'https://new.siemens.com/global/en/general/contact.html',
            'https://new.siemens.com/global/de/general/kontakt.html'
        ],
        'cookieNotice': [
            'Cookie Notice',
            'Cookie-Hinweise'
        ],
        'cookieNoticeLink': [
            'https://new.siemens.com/global/en/general/cookie-notice.html',
            'https://new.siemens.com/global/de/general/cookie-richtlinien.html'
        ],
        'corporateInformation': [
            'Corporate Information',
            'Impressum'
        ],
        'corporateInformationLink': [
            'https://new.siemens.com/global/en/general/legal.html',
            'https://new.siemens.com/global/de/general/legal.html'
        ],
        'digitalId': [
            'Digital ID',
            'Digitales Zertifikat'
        ],
        'digitalIdLink': [
            'https://new.siemens.com/global/en/general/digital-id.html',
            'https://new.siemens.com/global/de/general/digitales-zertifikat.html'
        ],
        'siemensWebsite': [
            'siemens.com Global Website',
            'siemens.com Germany'
        ],
        'siemensWebsiteLink': [
            'https://new.siemens.com/global/en.html',
            'https://new.siemens.com/global/de.html'
        ],
        'privacyNotice': [
            'Privacy Notice',
            'Datenschutz'
        ],
        'privacyNoticeLink': [
            'https://new.siemens.com/global/en/general/privacy-notice.html',
            'https://new.siemens.com/global/de/general/datenschutz.html'
        ],
        'siemens': [
            '© Siemens, 1996 - {date}',
            '© Siemens, 1996 - {date}'
        ],
        'termsOfUse': [
            'Terms of use',
            'Nutzungsbedingungen'
        ],
        'termsOfUseLink': [
            'https://new.siemens.com/global/en/general/terms-of-use.html',
            'https://new.siemens.com/global/de/general/nutzungsbedingungen.html'
        ]
    },
    'header': {
        'settings': [
            'Settings',
            'Settings'
        ],
        'language': [
            'Language',
            'Sprache'
        ],
        'languages': {
            'en': [
                'English',
                'English'
            ],
            'de': [
                'Deutsch',
                'Deutsch'
            ]
        },
        'skipToMainContent': [
            'Skip to main content',
            'Skip to main content'
        ],
        'support': [
            'Support',
            'Unterstützung'
        ],
        'reportProblem': [
            'Report a problem',
            'Ein Problem melden'
        ]
    }
});
