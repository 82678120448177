import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { PaginatedList, User } from '../../../models';
import { ROLES, TRANSLATIONS } from '../../../constants';
import Button, { ButtonType } from '../../common/Button';
import Checkbox from '../../common/Checkbox';
import ContentContainer from '../../common/ContentContainer';
import Flex, { FlexDirection, FlexJustification } from '../../common/Flex';
import Link from '../../common/Link';
import List, { ListItem } from '../../common/List';
import Modal from '../../common/Modal';
import Pagination from '../../common/Pagination';
import SpacingContainer from '../../common/SpacingContainer';
import Title from '../../common/Title';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import TextInput from '../../common/TextInput';
import Table from '../../common/Table';
import Tooltip from '../../common/Tooltip';

interface UserTableProps {
    users: PaginatedList<User>;
    onGetUsers: (offset: number, limit: number, orderBy: string, orderType: string) => Promise<void>;
    onUserChange: (user: User) => Promise<void>;
}

const UserTable = ({ users, onGetUsers, onUserChange }: UserTableProps) => {
    const dark = useAppSelector(state => state.layout.dark);
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const [sortedIndex, setSortedIndex] = useState(0);
    const [reverse, setReverse] = useState(false);
    const [user, setUser] = useState(null);
    const [shouldDisplayModal, setShouldDisplayModal] = useState(null);

    const openModalWindow = (user: User) => {
        setUser(user);
        setShouldDisplayModal(true);
    };

    const closeModalWindow = async () => {
        setUser(null);
        setShouldDisplayModal(false);
    };

    const saveModalWindow = async () => {
        closeModalWindow();
        await onUserChange(user);
    };

    const selectRole = (roleName: string) => {
        const selectedRoles = hasRole(roleName)
            ? user.roles.filter((x: string) => x !== roleName)
            : [...user.roles, roleName];

        setUser({ ...user, roles: selectedRoles });
    };

    const hasRole = (roleName: string) => {
        return user.roles.some((x: string) => x === roleName);
    };

    const getOrderType = (currentOrderBy: string, nextOrderBy: string, orderType: string) => {
        let currentOrderType;

        if (currentOrderBy === nextOrderBy) {
            if (orderType === 'DESC') {
                currentOrderType = 'ASC';
                setReverse(false);
            } else {
                currentOrderType = 'DESC';
                setReverse(true);
            }
        } else {
            currentOrderType = 'DESC';
            setReverse(true);
        }

        return currentOrderType;
    };

    const renderWithTooltip = (content: string) => {
        return (
            <>
                <Tooltip id={`id-${content}`} text={content}>
                    {content}
                </Tooltip>
            </>
        );
    };

    const renderModalWindow = () => {
        const roleList = [ROLES.ADMIN, ROLES.SIEMENS_REPRESENTATIVE, ROLES.CONTENT_EDITOR];

        return shouldDisplayModal && (
            <Modal dark={dark} open={true}>
                <SpacingContainer>
                    <Title style={{ marginBottom: 30 }} text={translate(translations.user.editUser)} uppercase bold />
                    <TextInput dark={dark} label={translate(translations.common.userName)} value={user.userName} name='userName' disabled onChange={() => { }} />
                    <TextInput dark={dark} label={translate(translations.common.email)} value={user.email} name='email' disabled onChange={() => { }} />
                    <TextInput dark={dark} label={translate(translations.common.gid)} value={user.gid} name='gid' disabled onChange={() => { }} />
                    <List
                        header={translate(translations.common.roles)}
                        items={roleList.map<ListItem>(x => ({
                            key: x,
                            content: <Checkbox dark={dark} checked={hasRole(x)} label={x} onChange={() => selectRole(x)} />
                        }))}
                    />
                    <Flex style={{ marginTop: 30 }} direction={FlexDirection.Row} justification={FlexJustification.FlexEnd} gap={5}>
                        <Button dark={dark} type={ButtonType.Secondary} onClick={closeModalWindow}>{translate(translations.action.cancel)}</Button>
                        <Button dark={dark} type={ButtonType.Primary} onClick={saveModalWindow}>{translate(translations.action.save)}</Button>
                    </Flex>
                </SpacingContainer>
            </Modal>
        );
    };

    const renderRowActions = (user: User) => {

        return (
            <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd}>
                <Link dark={dark} text={translate(translations.action.edit)} onClick={() => openModalWindow(user)} />
            </Flex>
        );
    };

    const renderTable = () => {
        const currentOrderBy = users && users.orderBy ? users.orderBy : 'UserName';
        let currentOrderType = users && users.orderType ? users.orderType : 'ASC';

        const handleHeaderClick = (index: number) => {
            setSortedIndex(index);

            switch (index) {
                case 0:
                    currentOrderType = getOrderType(currentOrderBy, 'UserName', currentOrderType);
                    onGetUsers(0, 0, 'UserName', currentOrderType);
                    break;
                case 1:
                    currentOrderType = getOrderType(currentOrderBy, 'Email', currentOrderType);
                    onGetUsers(0, 0, 'Email', currentOrderType);
                    break;
                case 2:
                    currentOrderType = getOrderType(currentOrderBy, 'GID', currentOrderType);
                    onGetUsers(0, 0, 'GID', currentOrderType);
                    break;
                default:
                    break;
            }
        };

        return users && (
            <>
                <Table
                    dark={dark}
                    hoverable
                    onHeaderClick={handleHeaderClick}
                    indexSortedBy={sortedIndex}
                    reverse={reverse}
                    columns={[
                        { label: translate(translations.common.userName), min: '100px', max: '3fr', bold: true },
                        { label: translate(translations.common.email), min: '100px', max: '3fr', bold: true },
                        { label: translate(translations.common.gid), min: '100px', max: '3fr', bold: true },
                        { label: translate(translations.common.roles), min: '100px', max: '5fr', bold: true },
                        { label: '', min: '100px', max: '1fr', bold: true }
                    ]}
                    rows={[
                        ...users.items.map(x => (
                            {
                                key: x.gid,
                                cells: [
                                    { content: renderWithTooltip(x.userName) },
                                    { content: renderWithTooltip(x.email) },
                                    { content: renderWithTooltip(x.gid) },
                                    { content: renderWithTooltip(x.roles.toString()) },
                                    { content: renderRowActions(x) }]
                            })),
                        { key: 'divider', divider: true, cells: [] }
                    ]}
                />
                <Flex direction={FlexDirection.Row} justification={FlexJustification.Center} style={{ marginTop: 10, marginBottom: 40 }}>
                    <Pagination dark={dark} activePage={(users.offset / users.limit)} count={Math.ceil(users.count / users.limit)}
                        onChange={x => onGetUsers(x * users.limit, users.limit, users.orderBy, users.orderType)} />
                </Flex>
            </>
        );
    };

    const renderPage = () => {
        return (
            <>
                <ContentContainer style={{ marginTop: 30, marginBottom: 50 }}>
                    {renderTable()}
                </ContentContainer>
                {renderModalWindow()}
            </>
        );
    };

    return renderPage();
};
export default UserTable;
