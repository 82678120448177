import ReactHtmlParser from 'react-html-parser';
import { Language } from '../enums';
import { Content } from '../models';

export class ContentService {
    static addEnglish = (languages: string[]) => ['en', ...languages.filter(x => x !== 'en')];

    static getFirstCharacters = (text: string, count: number) => `${text.substring(0, count).replace(/[^a-z]*$/i, '')}...`;

    static getLength = (text: string) => {
        const match = ContentService.removeTags(text ? text : '').match(/&[^;]+;|./g);

        return match ? match.length : 0;
    };

    static isValid = (text: string, maxLenght?: number) => {
        const length = ContentService.getLength(text);

        return length > 0 && (!maxLenght || length <= maxLenght);
    };

    static removeTags = (text: string) => text.replace(/<[^>]*>/g, '');

    static translate = (content: Content, language: Language): string => content[Language[language].toString().toLowerCase() as keyof Content].toString();

    static translateWithoutTags(content: Content, language: Language) {
        const translation = ContentService.translate(content, language);

        if (!translation) {
            return '';
        }

        const tags = ['p', 'li'];
        const replaceableValues = [/\.\. /g, /\. \. /g, /\n. /g, / \. /g];

        const temporaryDiv = document.createElement('div');
        temporaryDiv.innerHTML = tags.reduce((html, tag) => html.replace(new RegExp(`</${tag}>`, 'g'), `. </${tag}>`), translation);

        const text = temporaryDiv.textContent || temporaryDiv.innerText || '';

        return replaceableValues.reduce((filteredText, value) => filteredText.replace(value, '. '), text.replace(/^[^a-z]*/i, ''));
    }

    static translateWithParsing = (content: Content, language: Language) => {
        let contentsToParse = ContentService.translate(content, language);

        contentsToParse = contentsToParse.replace(new RegExp('href="http://', 'g'), 'href="');
        contentsToParse = contentsToParse.replace(new RegExp('href="https://', 'g'), 'href="');
        contentsToParse = contentsToParse.replace(new RegExp('href="', 'g'), 'href="http://');

        return ReactHtmlParser(contentsToParse);
    };
}
