import { Content } from '../models';

export const EMPTY_CONTENT: Content = {
    id: null,
    de: '',
    en: '',
    es: '',
    gr: '',
    it: ''
};
