import React, { useEffect, useState } from 'react';
import { Divider } from '@material-ui/core';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { TRANSLATIONS } from '../../../constants';
import { MachineCollection, Product } from '../../../models';
import ProductItem from './ProductItem';
import ProductView from '../product/ProductView';
import Button, { ButtonType } from '../../common/Button';
import Flex, { FlexDirection, FlexJustification } from '../../common/Flex';

interface ResultProps {
    machineCollection: MachineCollection;
    products: Product[];
    productCount: number;
    secondaryFilters: JSX.Element;
    onFavouriteToggle: (product: Product) => void;
    showAll?: boolean;
    onGetNextProducts?: (offset: number) => Promise<void>;
}

const Result = ({ machineCollection, products, productCount, secondaryFilters, onFavouriteToggle, onGetNextProducts, showAll }: ResultProps) => {
    const dark = useAppSelector(state => state.layout.dark);
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const [count, setCount] = useState(0);
    const [shouldShowLoadMore, setShouldShowLoadMore] = useState(true);
    
    useEffect(() => {
        if (products && count !== products.length) {
            setCount(products.length);
            
            if (products.length % 50 !== 0 || (!productCount || productCount === 0)) {
                setShouldShowLoadMore(false);
            } else {
                setShouldShowLoadMore(true);
            }
        }
    }, [products]);
    
    const renderResult = () => {
        return (
            <div className='result-container'>
                <div className='result'>
                    {!showAll && products && products.map(x =>
                        <ProductItem dark={dark} key={x.id} machineCollection={machineCollection} product={x} onFavouriteToggle={x => onFavouriteToggle(x)} />
                    )}
                    {showAll && products && products.map((x, index, array) =>
                        <>
                            <ProductView key={x.id} product={x} />
                            <br />
                            {index !== array.length - 1 && <Divider />}
                        </>
                    )}
                    {products && shouldShowLoadMore && 
                        <div id='no-print'>
                            <Flex style={{ marginTop: '10px' }} justification={FlexJustification.Center} direction={FlexDirection.Row}>
                                <Button dark={dark} type={ButtonType.Primary} onClick={() => onGetNextProducts(count)}>{`${translate(translations.action.showMore)} (${count}/${productCount})`}</Button>
                            </Flex>
                        </div>
                    }
                </div>
                {!showAll &&
                    <div className='secondary-filters-container'>
                        <div className={`secondary-filters ${dark ? 'dark' : ''}`}>
                            {secondaryFilters}
                        </div>
                    </div>
                }
            </div>
        );
    };

    return renderResult();
};
export default Result;
