import { HeaderTabType } from '../enums';
import { Tab } from '../components/layout/HeaderTabs';
import { AuthService } from '../services';
import { DEFAULT_MACHINE_COLLECTION_ID } from './DefaultMachineCollectionId';

export const TABS: Tab[] = [
    { title: 'ebooklets', to: '/collections/selection', type: HeaderTabType.MachineCollections, available: false },
    { title: 'Machine Finder', to: `/collection/${DEFAULT_MACHINE_COLLECTION_ID}`, type: HeaderTabType.MachineCollection, available: true },
    { title: 'My Machines', to: '/products', type: HeaderTabType.MyProducts, available: AuthService.isAuthenticated() },
    { title: 'my eBooklets', to: '/collections', type: HeaderTabType.MyMachineCollections, available: AuthService.isAdmin() },
    { title: 'Categories', to: '/categories', type: HeaderTabType.Categories, available: AuthService.isAdmin() },
    { title: 'Fairs', to: '/tags', type: HeaderTabType.Tags, available: false, chiefEditorRequired: true },
    { title: 'Users', to: '/users', type: HeaderTabType.Users, available: AuthService.isAdmin() },
    { title: 'Export', to: '/export', type: HeaderTabType.Export, available: AuthService.isAdmin(), chiefEditorRequired: true }
];
