import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Api, apiCall, AuthService, ProductService } from '../../../services';
import { PaginatedList, Product, ReleasedMachineCollection } from '../../../models';
import { HeaderTabType, ProductTableName } from '../../../enums';
import { DEFAULT_MACHINE_COLLECTION_ID, DEFAULT_ROW_VALUES, ROLES, TRANSLATIONS } from '../../../constants';
import ProductTable from './ProductTable';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { SnackbarType } from '../../common/Snackbar';
import {
    addSnackbar,
    setActiveTabType,
    setChiefEditorProductsLoading,
    setIncompleteProductsLoading,
    setInReviewProductsLoading, setLoading,
    setProductRequestsLoading,
    setProductsLoading,
    setReviewedProductsLoading
} from '../../../store';

const ProductTableManager = () => {
    const dark = useAppSelector(state => state.layout.dark);
    const dispatch = useDispatch();
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const controller = new AbortController();
    const signal = controller.signal;
    const [chiefEditorProducts, setChiefEditorProducts] = useState<PaginatedList<Product>>(null);
    const [incompleteProducts, setIncompleteProducts] = useState<PaginatedList<Product>>(null);
    const [inReviewProducts, setInReviewProducts] = useState<PaginatedList<Product>>(null);
    const [machineCollections, setMachineCollections] = useState([]);
    const [productRequests, setProductRequests] = useState<PaginatedList<Product>>(null);
    const [products, setProducts] = useState<PaginatedList<Product>>(null);
    const [rowValues, setRowValues] = useState(DEFAULT_ROW_VALUES);
    const [tags, setTags] = useState([]);
    const [reviewedProducts, setReviewProducts] = useState<PaginatedList<Product>>(null);

    useEffect(() => {
        dispatch(setActiveTabType(HeaderTabType.MyProducts));

        window.scrollTo(0, 0);

        const shouldLoadRequests = AuthService.hasRole([ROLES.SIEMENS_REPRESENTATIVE, ROLES.ADMIN]);

        loadProductsAndTags(shouldLoadRequests);

        return () => {
            controller.abort();
            Api.cancelProductRequests();
        };
    }, []);

    const loadProductsAndTags = async (shouldLoadRequests = false) => {
        const startingUrl = window.location.pathname;

        dispatch(setProductsLoading(true));
        if (shouldLoadRequests) {
            dispatch(setChiefEditorProductsLoading(true));
            dispatch(setIncompleteProductsLoading(true));
            dispatch(setInReviewProductsLoading(true));
            dispatch(setProductRequestsLoading(true));
            dispatch(setReviewedProductsLoading(true));
        }

        const machineCollectionsResult = await handleMachineCollectionsLoad();

        const myMachineData = JSON.parse(window.sessionStorage.getItem('myMachineData'));
        const newRowValues = DEFAULT_ROW_VALUES;

        if (myMachineData) {
            newRowValues.myMachinesTable = parseInt(myMachineData.rows, 10);
        }

        const productResponsePromise = apiCall(
            products
                ? Api.getProductsForUser(signal, products.offset, rowValues.myMachinesTable, products.orderBy, products.orderType)
                : myMachineData
                    ? Api.getProductsForUser(signal, myMachineData.page * myMachineData.rows, newRowValues.myMachinesTable, myMachineData.orderBy, myMachineData.orderType)
                    : Api.getProductsForUser(signal, 0, rowValues.myMachinesTable, 'LastChangeDate', 'DESC'),
            async x => {
                setProducts({ ...x.data, items: x.data.items.map(ProductService.convertDates) });
                dispatch(setProductsLoading(false));
            },
            async () => {
                if (!signal.aborted) {
                    dispatch(addSnackbar({ text: translate(translations.error.productsLoad).toString(), type: SnackbarType.Error, dark: dark }));
                    dispatch(setProductsLoading(false));
                }
            }
        );

        const tagPromise = apiCall(
            Api.getTags(signal),
            async x => {
                setTags(x.data);
            },
            async () => {
                if (!signal.aborted) {
                    dispatch(addSnackbar({ text: translate(translations.error.tagsLoad).toString(), type: SnackbarType.Error, dark: dark }));
                }
            }
        );

        if (shouldLoadRequests) {
            const shouldLoadChiefEditorRequests = machineCollectionsResult.length
                && machineCollectionsResult.find(x => x.id === DEFAULT_MACHINE_COLLECTION_ID).chiefEditorEmails.some((x: string) => x === AuthService.getUserEmail());
            let chiefEditorRequestsPromise;
            let inReviewProductsPromise;

            if (shouldLoadChiefEditorRequests) {
                const chiefEditorData = JSON.parse(window.sessionStorage.getItem('chiefEditorData'));

                if (chiefEditorData) {
                    newRowValues.chiefEditorTable = parseInt(chiefEditorData.rows, 10);
                }

                chiefEditorRequestsPromise = apiCall(
                    chiefEditorProducts
                        ? Api.getChiefEditorProductReviews(signal, chiefEditorProducts.offset, rowValues.chiefEditorTable, chiefEditorProducts.orderBy, 
                            chiefEditorProducts.orderType)
                        : chiefEditorData
                            ? Api.getChiefEditorProductReviews(signal, chiefEditorData.page * chiefEditorData.rows, newRowValues.chiefEditorTable, chiefEditorData.orderBy, 
                                chiefEditorData.orderType)
                            : Api.getChiefEditorProductReviews(signal, 0, rowValues.chiefEditorTable, 'LastChangeDate', 'DESC'),
                    async x => {
                        setChiefEditorProducts({ ...x.data, items: x.data.items.map(ProductService.convertDates) });
                        dispatch(setChiefEditorProductsLoading(false));
                    },
                    async () => {
                        if (!signal.aborted) {
                            dispatch(addSnackbar({ text: translate(translations.error.productsLoad).toString(), type: SnackbarType.Error, dark: dark }));
                            dispatch(setChiefEditorProductsLoading(false));
                        }
                    }
                );

                const inReviewData = JSON.parse(window.sessionStorage.getItem('inReviewData'));

                if (inReviewData) {
                    newRowValues.inReviewTable = parseInt(inReviewData.rows, 10);
                }

                inReviewProductsPromise = apiCall(
                    inReviewProducts
                        ? Api.getProductsInReview(signal, inReviewProducts.offset, rowValues.inReviewTable, inReviewProducts.orderBy, inReviewProducts.orderType)
                        : inReviewData
                            ? Api.getProductsInReview(signal, inReviewData.page * inReviewData.rows, newRowValues.inReviewTable, inReviewData.orderBy, 
                                inReviewData.orderType)
                            : Api.getProductsInReview(signal, 0, rowValues.inReviewTable, 'LastChangeDate', 'DESC'),
                    async x => {
                        setInReviewProducts({ ...x.data, items: x.data.items.map(ProductService.convertDates) });
                        dispatch(setInReviewProductsLoading(false));
                    },
                    async () => {
                        if (!signal.aborted) {
                            dispatch(addSnackbar({ text: translate(translations.error.productsLoad).toString(), type: SnackbarType.Error, dark: dark }));
                            dispatch(setInReviewProductsLoading(false));
                        }
                    }
                );
            }

            const requestsData = JSON.parse(window.sessionStorage.getItem('requestsData'));

            if (requestsData) {
                newRowValues.requestTable = parseInt(requestsData.rows, 10);
            }

            const productRequestPromise = apiCall(
                productRequests
                    ? Api.getProductRequestsForUser(signal, productRequests.offset, rowValues.requestTable, productRequests.orderBy, productRequests.orderType)
                    : requestsData
                        ? Api.getProductRequestsForUser(signal, requestsData.page * requestsData.rows, newRowValues.requestTable, requestsData.orderBy, 
                            requestsData.orderType)
                        : Api.getProductRequestsForUser(signal, 0, rowValues.requestTable, 'LastChangeDate', 'DESC'),
                async x => {
                    setProductRequests({ ...x.data, items: x.data.items.map(ProductService.convertDates) });
                    dispatch(setProductRequestsLoading(false));
                },
                async () => {
                    if (!signal.aborted) {
                        dispatch(addSnackbar({ text: translate(translations.error.productsLoad).toString(), type: SnackbarType.Error, dark: dark }));
                        dispatch(setProductRequestsLoading(false));
                    }
                }
            );

            const incompleteData = JSON.parse(window.sessionStorage.getItem('incompleteData'));

            if (incompleteData) {
                newRowValues.incompleteTable = parseInt(incompleteData.rows, 10);
            }

            const incompleteProductsPromise = apiCall(
                incompleteProducts
                    ? Api.getIncompleteProductsForUser(signal, incompleteProducts.offset, rowValues.incompleteTable, incompleteProducts.orderBy, incompleteProducts.orderType)
                    : incompleteData
                        ? Api.getIncompleteProductsForUser(signal, incompleteData.page * incompleteData.rows, newRowValues.incompleteTable, incompleteData.orderBy, 
                            incompleteData.orderType)
                        : Api.getIncompleteProductsForUser(signal, 0, rowValues.incompleteTable, 'LastChangeDate', 'DESC'),
                async x => {
                    setIncompleteProducts({ ...x.data, items: x.data.items.map(ProductService.convertDates) });
                    dispatch(setIncompleteProductsLoading(false));
                },
                async () => {
                    if (!signal.aborted) {
                        dispatch(addSnackbar({ text: translate(translations.error.productsLoad).toString(), type: SnackbarType.Error, dark: dark }));
                        dispatch(setIncompleteProductsLoading(false));
                    }
                }
            );

            const reviewedData = JSON.parse(window.sessionStorage.getItem('reviewedData'));

            if (reviewedData) {
                newRowValues.reviewedTable = parseInt(reviewedData.rows, 10);
            }

            const reviewedProductsPromise = apiCall(
                reviewedProducts
                    ? Api.getReviewedProductsForUser(signal, reviewedProducts.offset, rowValues.reviewedTable, reviewedProducts.orderBy, reviewedProducts.orderType)
                    : reviewedData
                        ? Api.getReviewedProductsForUser(signal, reviewedData.page * reviewedData.rows, newRowValues.reviewedTable, reviewedData.orderBy, 
                            reviewedData.orderType)
                        : Api.getReviewedProductsForUser(signal, 0, rowValues.reviewedTable, 'LastChangeDate', 'DESC'),
                async x => {
                    setReviewProducts({ ...x.data, items: x.data.items.map(ProductService.convertDates) });
                    dispatch(setReviewedProductsLoading(false));
                },
                async () => {
                    if (!signal.aborted) {
                        dispatch(addSnackbar({ text: translate(translations.error.productsLoad).toString(), type: SnackbarType.Error, dark: dark }));
                        dispatch(setReviewedProductsLoading(false));
                    }
                }
            );

            setRowValues(newRowValues);

            await productRequestPromise;
            await incompleteProductsPromise;
            await inReviewProductsPromise;
            await reviewedProductsPromise;
            await chiefEditorRequestsPromise;
        }

        await productResponsePromise;
        await tagPromise;

        if (startingUrl === window.location.pathname) {
            dispatch(setLoading(false));
        }
    };

    const handleMachineCollectionsLoad = async (): Promise<ReleasedMachineCollection[]> => {
        let machineCollectionsResult: ReleasedMachineCollection[] = [];

        await apiCall(
            Api.getReleasedMachineCollections(signal),
            async x => {
                setMachineCollections(x.data);
                machineCollectionsResult = x.data;
            },
            async () => {
                if (!signal.aborted) {
                    dispatch(addSnackbar({ text: translate(translations.error.releasedCollectionsLoad).toString(), type: SnackbarType.Error, dark: dark }));
                }
            }
        );

        return machineCollectionsResult;
    };

    const handleGetProducts = async (offset: number, limit: number, orderBy: string, orderType: string, name?: ProductTableName) => {
        switch (name) {
            case ProductTableName.MyMachines:
                dispatch(setProductsLoading(true));
                await apiCall(
                    Api.getProductsForUser(signal, offset, limit !== 0 ? limit : rowValues.myMachinesTable, orderBy, orderType),
                    async x => {
                        if (limit !== 0) {
                            const myMachineData = { 'rows': limit.toString(), 'page': offset / limit, 'orderBy': orderBy, 'orderType': orderType };

                            window.sessionStorage.setItem('myMachineData', JSON.stringify(myMachineData));
                            setRowValues({ ...rowValues, myMachinesTable: limit });
                        }
                        setProducts({ ...x.data, items: x.data.items.map(ProductService.convertDates) });
                        dispatch(setProductsLoading(false));
                    },
                    async () => {
                        dispatch(addSnackbar({ text: translate(translations.error.productsLoad).toString(), type: SnackbarType.Error, dark: dark }));
                        dispatch(setProductsLoading(false));
                    }
                );
                break;
            case ProductTableName.IncompleteProducts:
                dispatch(setIncompleteProductsLoading(true));
                await apiCall(
                    Api.getIncompleteProductsForUser(signal, offset, limit !== 0 ? limit : rowValues.incompleteTable, orderBy, orderType),
                    async x => {
                        if (limit !== 0) {
                            const incompleteData = { 'rows': limit.toString(), 'page': offset / limit, 'orderBy': orderBy, 'orderType': orderType };

                            window.sessionStorage.setItem('incompleteData', JSON.stringify(incompleteData));
                            setRowValues({ ...rowValues, incompleteTable: limit });
                        }
                        setIncompleteProducts({ ...x.data, items: x.data.items.map(ProductService.convertDates) });
                        dispatch(setIncompleteProductsLoading(false));
                    },
                    async () => {
                        dispatch(addSnackbar({ text: translate(translations.error.productsLoad).toString(), type: SnackbarType.Error, dark: dark }));
                        dispatch(setIncompleteProductsLoading(false));
                    }
                );
                break;
            case ProductTableName.InReviewProducts:
                dispatch(setInReviewProductsLoading(true));
                await apiCall(
                    Api.getProductsInReview(signal, offset, limit !== 0 ? limit : rowValues.inReviewTable, orderBy, orderType),
                    async x => {
                        if (limit !== 0) {
                            const inReviewData = { 'rows': limit.toString(), 'page': offset / limit, 'orderBy': orderBy, 'orderType': orderType };

                            window.sessionStorage.setItem('inReviewData', JSON.stringify(inReviewData));
                            setRowValues({ ...rowValues, inReviewTable: limit });
                        }
                        setInReviewProducts({ ...x.data, items: x.data.items.map(ProductService.convertDates) });
                        dispatch(setInReviewProductsLoading(false));
                    },
                    async () => {
                        dispatch(addSnackbar({ text: translate(translations.error.productsLoad).toString(), type: SnackbarType.Error, dark: dark }));
                        dispatch(setInReviewProductsLoading(false));
                    }
                );
                break;

            case ProductTableName.ProductRequests:
                dispatch(setProductRequestsLoading(true));
                await apiCall(
                    Api.getProductRequestsForUser(signal, offset, limit !== 0 ? limit : rowValues.requestTable, orderBy, orderType),
                    async x => {
                        if (limit !== 0) {
                            const requestsData = { 'rows': limit.toString(), 'page': offset / limit, 'orderBy': orderBy, 'orderType': orderType };

                            window.sessionStorage.setItem('requestsData', JSON.stringify(requestsData));
                            setRowValues({ ...rowValues, requestTable: limit });
                        }
                        setProductRequests({ ...x.data, items: x.data.items.map(ProductService.convertDates) });
                        dispatch(setProductRequestsLoading(false));
                    },
                    async () => {
                        dispatch(addSnackbar({ text: translate(translations.error.productsLoad).toString(), type: SnackbarType.Error, dark: dark }));
                        dispatch(setProductRequestsLoading(false));
                    }
                );
                break;
            case ProductTableName.ReviewedProducts:
                dispatch(setReviewedProductsLoading(true));
                await apiCall(
                    Api.getReviewedProductsForUser(signal, offset, limit !== 0 ? limit : rowValues.reviewedTable, orderBy, orderType),
                    async x => {
                        if (limit !== 0) {
                            const reviewedData = { 'rows': limit.toString(), 'page': offset / limit, 'orderBy': orderBy, 'orderType': orderType };

                            window.sessionStorage.setItem('reviewedData', JSON.stringify(reviewedData));
                            setRowValues({ ...rowValues, reviewedTable: limit });
                        }
                        setReviewProducts({ ...x.data, items: x.data.items.map(ProductService.convertDates) });
                        dispatch(setReviewedProductsLoading(false));
                    },
                    async () => {
                        dispatch(addSnackbar({ text: translate(translations.error.productsLoad).toString(), type: SnackbarType.Error, dark: dark }));
                        dispatch(setReviewedProductsLoading(false));
                    }
                );
                break;
            case ProductTableName.ChiefEditorProducts:
                dispatch(setChiefEditorProductsLoading(true));
                await apiCall(
                    Api.getChiefEditorProductReviews(signal, offset, limit !== 0 ? limit : rowValues.chiefEditorTable, orderBy, orderType),
                    async x => {
                        if (limit !== 0) {
                            const chiefEditorData = { 'rows': limit.toString(), 'page': offset / limit, 'orderBy': orderBy, 'orderType': orderType };

                            window.sessionStorage.setItem('chiefEditorData', JSON.stringify(chiefEditorData));
                            setRowValues({ ...rowValues, chiefEditorTable: limit });
                        }
                        setChiefEditorProducts({ ...x.data, items: x.data.items.map(ProductService.convertDates) });
                        dispatch(setChiefEditorProductsLoading(false));
                    },
                    async () => {
                        dispatch(addSnackbar({ text: translate(translations.error.productsLoad).toString(), type: SnackbarType.Error, dark: dark }));
                        dispatch(setChiefEditorProductsLoading(false));
                    }
                );
                break;
            default:
                break;
        }
    };

    const handleToggleDigitalTwin = async (productId: string, enableDigitalTwin: boolean) => {
        dispatch(setReviewedProductsLoading(true));
        
        await apiCall(
            Api.updateDigitalTwinOnProduct(productId, enableDigitalTwin),
            async () => {
                dispatch(addSnackbar({ text: translate(translations.dialog.successfulSave).toString(), type: SnackbarType.Success, dark: dark }));
                await handleGetProducts(reviewedProducts.offset, reviewedProducts.limit, reviewedProducts.orderBy,
                    reviewedProducts.orderType, ProductTableName.ReviewedProducts);
            },
            async () => {
                dispatch(addSnackbar({ text: translate(translations.error.productUpdate).toString(), type: SnackbarType.Error, dark: dark }));
            }
        );
    };

    const handleProductCopy = async (productId: string) => {
        dispatch(setProductsLoading(true));

        await apiCall(
            Api.copyProduct(productId),
            async () => {
                dispatch(addSnackbar({ text: translate(translations.dialog.successfulCopy).toString(), type: SnackbarType.Success, dark: dark }));
                await loadProductsAndTags();
            },
            async () => {
                dispatch(addSnackbar({ text: translate(translations.error.productCopy).toString(), type: SnackbarType.Error, dark: dark }));
            }
        );
    };

    const handleProductDelete = async (id: string) => {
        dispatch(setProductsLoading(true));

        const shouldLoadAll = AuthService.hasRole([ROLES.SIEMENS_REPRESENTATIVE, ROLES.ADMIN]);

        await apiCall(
            Api.deleteProduct(id),
            async () => {
                dispatch(addSnackbar({ text: translate(translations.dialog.successfulDelete).toString(), type: SnackbarType.Success, dark: dark }));
                await loadProductsAndTags(shouldLoadAll);
            },
            async () => {
                dispatch(addSnackbar({ text: translate(translations.error.productDelete).toString(), type: SnackbarType.Error, dark: dark }));
                dispatch(setProductsLoading(false));
            }
        );
    };

    const handleAddTagToProduct = async (productId: string, tagId: string) => {
        dispatch(setLoading(true));

        await apiCall(
            Api.addTagToProduct(productId, tagId),
            async () => {
                dispatch(addSnackbar({ text: translate(translations.dialog.successfulSave).toString(), type: SnackbarType.Success, dark: dark }));
                dispatch(setLoading(false));
                await loadProductsAndTags();
            },
            async () => {
                dispatch(addSnackbar({ text: translate(translations.error.tagSave).toString(), type: SnackbarType.Error, dark: dark }));
                dispatch(setLoading(false));
            }
        );
    };

    const handleRemoveTagFromProduct = async (productId: string, tagId: string) => {
        dispatch(setLoading(true));

        await apiCall(
            Api.removeTagFromProduct(productId, tagId),
            async () => {
                dispatch(addSnackbar({ text: translate(translations.dialog.successfulDelete).toString(), type: SnackbarType.Success, dark: dark }));
                dispatch(setLoading(false));
                await loadProductsAndTags();
            },
            async () => {
                dispatch(addSnackbar({ text: translate(translations.error.tagDelete).toString(), type: SnackbarType.Error, dark: dark }));
                dispatch(setLoading(false));
            }
        );
    };

    const renderTable = () => {
        return (
            <div className={`compass-section ${dark ? 'dark' : ''}`}>
                <ProductTable machineCollections={machineCollections} chiefEditorProducts={chiefEditorProducts} incompleteProducts={incompleteProducts} 
                              inReviewProducts={inReviewProducts} productRequests={productRequests} products={products} tags={tags} reviewedProducts={reviewedProducts} 
                              rowValues={rowValues} onAddTagToProduct={handleAddTagToProduct} onGetProducts={handleGetProducts} 
                              onMachineCollectionsLoad={handleMachineCollectionsLoad} onProductCopy={handleProductCopy} onProductDelete={handleProductDelete} 
                              onToggleDigitalTwinProduct={handleToggleDigitalTwin} onRemoveTagFromProduct={handleRemoveTagFromProduct}
                />
            </div>
        );
    };

    return renderTable();
};
export default ProductTableManager;
