import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import Layout from './ts/components/layout/Layout';
import ProductApprovalManager from './ts/components/application/product/ProductApprovalManager';
import { DEFAULT_MACHINE_COLLECTION_ID } from './ts/constants';
import AuthorizationErrorManager from './ts/components/application/authentication/AuthorizationErrorManager';
import MachineCollectionViewManager from './ts/components/application/machineCollection/MachineCollectionViewManager';
import ProductViewManager from './ts/components/application/product/ProductViewManager';
import MachineCollectionTableManager from './ts/components/application/machineCollection/MachineCollectionTableManager';
import MachineCollectionListManager from './ts/components/application/machineCollection/MachineCollectionListManager';
import MachineCollectionWizardManager from './ts/components/application/machineCollection/MachineCollectionWizardManager';
import CompanyEditManager from './ts/components/application/company/CompanyEditManager';
import FakeLoginManager from './ts/components/application/authentication/FakeLoginManager';
import ExportManager from './ts/components/application/admin/ExportManager';
import ProductPreviewManager from './ts/components/application/product/ProductPreviewManager';
import ReviewWizardManager from './ts/components/application/review/ReviewWizardManager';
import ProductReleaseWizardManager from './ts/components/application/product/ProductReleaseWizardManager';
import ProductTableManager from './ts/components/application/product/ProductTableManager';
import ProductWizardManager from './ts/components/application/product/ProductWizardManager';
import TagTableManager from './ts/components/application/tag/TagTableManager';
import UserTableManager from './ts/components/application/admin/UserTableManager';
import CategoryManager from './ts/components/application/admin/CategoryManager';
import RequireAuth from './RequireAuth';
import DefaultCompanyManager from './ts/components/application/company/DefaultCompanyManager';
import CookieNotice from './ts/components/application/cookie/CookieNotice';

const routes = (
    <Routes>
        <Route path='/' element={<Navigate to={`/machine-finder/${DEFAULT_MACHINE_COLLECTION_ID}`} />} />
        <Route path='/*' element={<Layout><Outlet /><DefaultCompanyManager {...{}} /></Layout>}>
            <Route path='approval/:id' element={<ProductApprovalManager />} />
            <Route path='authorizationerror' element={<AuthorizationErrorManager />} />
            <Route path='cookienotice' element={<CookieNotice />} />
            <Route path='categories' element={<RequireAuth><CategoryManager /></RequireAuth>} />
            <Route path='collection/:id' element={<Navigate to={`/machine-finder/${DEFAULT_MACHINE_COLLECTION_ID}`} />} />
            <Route path='machine-finder/:id' element={<MachineCollectionViewManager />} />
            <Route path='collection/:collectionId/product/:productId' element={<ProductViewManager />} />
            <Route path='machine-finder/:collectionId/product/:productId' element={<ProductViewManager />} />
            <Route path='collections' element={<RequireAuth><MachineCollectionTableManager /></RequireAuth>} />
            <Route path='collections/selection' element={<MachineCollectionListManager />} />
            <Route path='collections/collectionwizard' element={<RequireAuth><MachineCollectionWizardManager /></RequireAuth>} />
            <Route path='collections/collectionwizard/:id' element={<RequireAuth><MachineCollectionWizardManager /></RequireAuth>} />
            <Route path='company' element={<CompanyEditManager />} />
            {process.env.REACT_APP_IS_DEVELOPMENT &&
                <Route path='fakelogin' element={<FakeLoginManager />} />
            }
            <Route path='export' element={<RequireAuth><ExportManager /></RequireAuth>} />
            <Route path='product/:id' element={<ProductPreviewManager />} />
            <Route path='product/:id/review' element={<RequireAuth><ReviewWizardManager /></RequireAuth>} />
            <Route path='productreleasewizard' element={<RequireAuth><ProductReleaseWizardManager /></RequireAuth>} />
            <Route path='productreleasewizard/:id' element={<RequireAuth><ProductReleaseWizardManager /></RequireAuth>} />
            <Route path='products' element={<RequireAuth><ProductTableManager /></RequireAuth>} />
            <Route path='productwizard' element={<RequireAuth><ProductWizardManager /></RequireAuth>} />
            <Route path='productwizard/:id' element={<RequireAuth><ProductWizardManager /></RequireAuth>} />
            <Route path='tags' element={<RequireAuth><TagTableManager /></RequireAuth>} />
            <Route path='users' element={<RequireAuth><UserTableManager /></RequireAuth>} />
        </Route>
    </Routes>
);

export default routes;
