import React, { useState } from 'react';
import { Categories, Category, CategoryGroup, CategorySubgroup, Content } from '../../../models';
import { EMPTY_CONTENT, TRANSLATIONS } from '../../../constants';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import Accordion from '../../common/Accordion';
import Button, { ButtonType } from '../../common/Button';
import Checkbox from '../../common/Checkbox';
import ContentContainer from '../../common/ContentContainer';
import Flex, { FlexDirection, FlexJustification } from '../../common/Flex';
import Link from '../../common/Link';
import List from '../../common/List';
import Modal from '../../common/Modal';
import SpacingContainer from '../../common/SpacingContainer';
import Title from '../../common/Title';
import TextInput from '../../common/TextInput';

interface CategoryFormProps {
    accordionsOpen: boolean[];
    categories: Categories;
    onAccordionToggle: (index: number) => void;
    onCategoryAdd: (parentId: number, content: Content) => Promise<void>;
    onCategoryChange: (content: Content, obsolete: boolean) => Promise<void>;
    onCategoryDelete: (id: number) => Promise<void>;
    onCategorySubgroupAdd: (parentId: number, content: Content) => Promise<void>;
    onCategorySubgroupChange: (content: Content) => Promise<void>;
    onCategorySubgroupDelete: (id: number) => Promise<void>;
}

const CategoryForm = ({ accordionsOpen, categories, onAccordionToggle, onCategoryAdd, onCategoryChange, onCategoryDelete, onCategorySubgroupAdd,
    onCategorySubgroupChange, onCategorySubgroupDelete }: CategoryFormProps) => {
    const dark = useAppSelector(state => state.layout.dark);
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const [categoryContent, setCategoryContent] = useState(null);
    const [obsolete, setObsolete] = useState(false);
    const [isSubgroup, setIsSubgroup] = useState(false);
    const [parentContent, setParentContent] = useState(null);
    const [shouldDisplayDialog, setShouldDisplayDialog] = useState(false);
    const [shouldDisplayModal, setShouldDisplayModal] = useState(false);

    const openModalWindow = (content: Content, parentContent: Content, isSubgroup: boolean, isObsolete: boolean) => {
        setCategoryContent({ ...content ? content : EMPTY_CONTENT });
        setObsolete(isObsolete);
        setIsSubgroup(isSubgroup);
        setParentContent(parentContent);
        setShouldDisplayModal(true);
    };

    const closeModalWindow = async () => {
        setCategoryContent(null);
        setObsolete(false);
        setParentContent(null);
        setShouldDisplayModal(false);
    };

    const saveModalWindow = async () => {
        closeModalWindow();

        if (categoryContent.id !== null) {
            if (isSubgroup) {
                await onCategorySubgroupChange(categoryContent);
            } else {
                await onCategoryChange(categoryContent, obsolete);
            }
        } else {
            if (isSubgroup) {
                await onCategorySubgroupAdd(parentContent.id, categoryContent);
            } else {
                await onCategoryAdd(parentContent.id, categoryContent);
            }
        }
    };

    const openDialog = (categoryContent: Content, isSubgroup: boolean) => {
        setCategoryContent(categoryContent);
        setIsSubgroup(isSubgroup);
        setShouldDisplayDialog(true);
    };

    const closeDialog = () => {
        setCategoryContent(null);
        setShouldDisplayDialog(false);
    };

    const confirmDelete = () => {
        closeDialog();

        if (isSubgroup) {
            onCategorySubgroupDelete(categoryContent.id);
        } else {
            onCategoryDelete(categoryContent.id);
        }
    };

    const handleContentChange = (value: string, name: string) => {
        setCategoryContent({ ...categoryContent, [name]: value });
    };

    const isModalValid = () => {
        return Boolean(categoryContent.en);
    };

    const renderDeleteDialog = () => {
        return shouldDisplayDialog && (
            <Modal dark={dark} open={shouldDisplayDialog}>
                <Title text={translate(translations.dialog.confirmDelete)} bold />
                <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd}>
                    <Link dark={dark} text={translate(translations.action.yes)} onClick={confirmDelete} />
                    <Link dark={dark} text={translate(translations.action.no)} onClick={closeDialog} />
                </Flex>
            </Modal>
        );
    };
    const renderModalWindow = () => {
        let title = '';
        
        if (shouldDisplayModal) {
            if (categoryContent.id !== null) {
                title = translate(translations.category.editCategory);
            } else if (isSubgroup) {
                title = `${translate(translations.category.createCategoryGroupFor)} ${parentContent.en}`;
            } else {
                title = `${translate(translations.category.createCategoryFor)} ${parentContent.en}`;
            }
        }
        
        return shouldDisplayModal && (
            <Modal dark={dark} open={true}>
                <SpacingContainer>
                    <Title style={{ marginBottom: 30 }} text={title} />
                    <TextInput dark={dark} name='en' label={translate(translations.language.en)} error={!categoryContent.en} value={categoryContent.en} 
                               onChange={handleContentChange} required />
                    <TextInput dark={dark} name='de' label={translate(translations.language.de)} value={categoryContent.de} onChange={handleContentChange} />
                    <TextInput dark={dark} name='gr' label={translate(translations.language.gr)} value={categoryContent.gr} onChange={handleContentChange} />
                    <TextInput dark={dark} name='it' label={translate(translations.language.it)} value={categoryContent.it} onChange={handleContentChange} />
                    <TextInput dark={dark} name='es' label={translate(translations.language.es)} value={categoryContent.es} onChange={handleContentChange} />
                    <Checkbox dark={dark} label={translate(translations.common.obsolete)} onChange={setObsolete} checked={obsolete} disabled={isSubgroup || categoryContent.id === null} />
                    <Flex style={{ marginTop: 30 }} direction={FlexDirection.Row} justification={FlexJustification.FlexEnd} gap={5}>
                        <Button dark={dark} type={ButtonType.Secondary} onClick={closeModalWindow}>{translate(translations.action.cancel)}</Button>
                        <Button dark={dark} type={ButtonType.Primary} disabled={!isModalValid()} onClick={saveModalWindow}>{translate(translations.action.save)}</Button>
                    </Flex>
                </SpacingContainer>
            </Modal>
        );
    };

    const renderCategory = (category: Category, isSubgroup = false) => {
        return (
            <>
                {category.content.en}
                <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd}>
                    {isSubgroup &&
                        <Link text={translate(translations.action.add)} onClick={() => openModalWindow(null, category.content, false, category.obsolete)} />
                    }
                    <Link text={translate(translations.action.edit)} onClick={() => openModalWindow(category.content, null, isSubgroup, category.obsolete)} />
                    {!category.isUsed &&
                        <>
                            <Link text={translate(translations.action.delete)} onClick={() => openDialog(category.content, isSubgroup)} />
                        </>
                    }
                </Flex>
            </>
        );
    };

    const renderCategorySubgroup = (categorySubroup: CategorySubgroup) => {
        return (
            <List
                key={categorySubroup.content.id}
                header={renderCategory(categorySubroup, true)}
                items={categorySubroup.categories.map(x => ({ key: x.content.id, content: renderCategory(x) }))}
            />
        );
    };

    const renderCategoryGroup = (categoryGroup: CategoryGroup, index: number) => {        
        return (
            <Accordion dark={dark} label={categoryGroup.content.en} open={accordionsOpen[index]} onClick={() => onAccordionToggle(index)}>
                <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd}>
                    <Link text={translate(translations.action.addGroup)} onClick={() => openModalWindow(null, categoryGroup.content, true, false)} />
                </Flex>
                {categoryGroup.subgroups.map(renderCategorySubgroup)}
            </Accordion>
        );
    };

    const renderCategories = () => {
        return categories && (
            <div style={{ marginTop: 30 }}>
                {categories.groups.map(renderCategoryGroup)}
            </div>
        );
    };

    const renderPage = () => {
        return (
            <>
                <ContentContainer style={{ marginTop: 30, marginBottom: 50 }}>
                    <Title text={translate(translations.category.categories)} uppercase bold />
                    {renderCategories()}
                </ContentContainer>
                {renderDeleteDialog()}
                {renderModalWindow()}
            </>
        );
    };

    return renderPage();
};
export default CategoryForm;
