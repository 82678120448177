import { ApiBase } from '.';
import {
    Categories,
    CategoryCreation,
    Company,
    Content, 
    Dictionary,
    MachineCollection,
    MachineCollectionCreation,
    MachineCollectionFilteringResult,
    MachineCollectionListItem,
    PaginatedList,
    Product,
    ProductCreation,
    ProductReview,
    ReleasedMachineCollection,
    RequestFilter,
    ServiceDataResponse,
    ServiceResponse,
    Tag,
    User
} from '../models';

export class Api extends ApiBase {
    static getMachineCollection(id: string) {
        return this.get(`api/machinecollection/${id}`) as Promise<ServiceDataResponse<MachineCollection>>;
    }

    static getMachineCollections(signal?: AbortSignal) {
        return this.get('api/machinecollection', signal) as Promise<ServiceDataResponse<MachineCollectionListItem[]>>;
    }

    static getMachineCollectionsForUser(signal?: AbortSignal) {
        return this.get('api/machinecollection/user', signal) as Promise<ServiceDataResponse<MachineCollectionListItem[]>>;
    }

    static getEditableMachineCollection(id: string) {
        return this.get(`api/machinecollection/edit/${id}`) as Promise<ServiceDataResponse<MachineCollectionCreation>>;
    }

    static getReleasedMachineCollections(signal?: AbortSignal) {
        return this.get('api/machinecollection/released', signal) as Promise<ServiceDataResponse<ReleasedMachineCollection[]>>;
    }
    
    static getQuickSearchProductsForMachineCollection(id: string) {
        return this.get(`api/MachineCollection/products/${id}`) as Promise<ServiceDataResponse<Dictionary<string>>>;
    }

    static filterMachineCollectionProducts(id: string, filter: RequestFilter) {
        return this.post(`api/machinecollection/products/${id}`, filter) as Promise<ServiceDataResponse<MachineCollectionFilteringResult>>;
    }

    static saveMachineCollection(machineCollection: MachineCollectionCreation) {
        return this.post('api/machinecollection', machineCollection) as Promise<ServiceResponse>;
    }

    static updateMachineCollection(machineCollection: MachineCollectionCreation) {
        return this.put('api/machinecollection', machineCollection) as Promise<ServiceResponse>;
    }

    static releaseMachineCollection(id: string) {
        return this.post(`api/machinecollection/${id}/status/release`) as Promise<ServiceResponse>;
    }

    static deleteMachineCollection(id: string) {
        return this.delete(`api/machinecollection/${id}`) as Promise<ServiceResponse>;
    }

    static cancelMachineCollectionRequests() {
        return this.delete('api/machinecollection/cancel') as Promise<ServiceResponse>;
    }

    static getCompanyForUser() {
        return this.get('api/company/user') as Promise<ServiceDataResponse<Company>>;
    }

    static getAssignedCompanies() {
        return this.get('api/company/assigned') as Promise<ServiceDataResponse<Company[]>>;
    }

    static saveCompany(company: Company) {
        return this.post('api/company', company) as Promise<ServiceDataResponse<Company>>;
    }

    static updateCompany(company: Company) {
        return this.put('api/company', company) as Promise<ServiceDataResponse<Company>>;
    }

    static getProduct(id: string) {
        return this.get(`api/product/${id}`) as Promise<ServiceDataResponse<Product>>;
    }

    static getProductByToken(token: string) {
        return this.get(`api/product/token/${token}`) as Promise<ServiceDataResponse<Product>>;
    }

    static getPublicProduct(id: string) {
        return this.get(`api/product/public/${id}`) as Promise<ServiceDataResponse<Product>>;
    }

    static getExportProducts() {
        return this.requestWithoutProcess('GET', 'api/product/export', null, false, 'text/csv') as Promise<Response>;
    }

    static getProductsForUser(signal?: AbortSignal, offset?: number, limit?: number, orderBy?: string, orderType?: string) {
        return this.get(`api/product${!isNaN(offset) && !isNaN(limit) && orderBy && orderType ? `?offset=${offset}&limit=${limit}&orderBy=${orderBy}&orderType=${orderType}` : ''}`, signal) as
            Promise<ServiceDataResponse<PaginatedList<Product>>>;
    }

    static getIncompleteProductsForUser(signal?: AbortSignal, offset?: number, limit?: number, orderBy?: string, orderType?: string) {
        return this.get(`api/product/incomplete${!isNaN(offset) && !isNaN(limit) && orderBy && orderType ? `?offset=${offset}&limit=${limit}&orderBy=${orderBy}&orderType=${orderType}` : ''}`, signal) as
            Promise<ServiceDataResponse<PaginatedList<Product>>>;
    }

    static getProductsInReview(signal?: AbortSignal, offset?: number, limit?: number, orderBy?: string, orderType?: string) {
        return this.get(`api/product/inreview${!isNaN(offset) && !isNaN(limit) && orderBy && orderType ? `?offset=${offset}&limit=${limit}&orderBy=${orderBy}&orderType=${orderType}` : ''}`, signal) as
            Promise<ServiceDataResponse<PaginatedList<Product>>>;
    }

    static getProductRequestForUser(id: string) {
        return this.get(`api/product/${id}/request`) as Promise<ServiceDataResponse<Product>>;
    }

    static getProductRequestsForUser(signal?: AbortSignal, offset?: number, limit?: number, orderBy?: string, orderType?: string) {
        return this.get(`api/product/request${!isNaN(offset) && !isNaN(limit) && orderBy && orderType ? `?offset=${offset}&limit=${limit}&orderBy=${orderBy}&orderType=${orderType}` : ''}`, signal) as
            Promise<ServiceDataResponse<PaginatedList<Product>>>;
    }

    static getChiefEditorProductReviews(signal?: AbortSignal, offset?: number, limit?: number, orderBy?: string, orderType?: string) {
        return this.get(`api/product/chiefeditor${!isNaN(offset) && !isNaN(limit) && orderBy && orderType ? `?offset=${offset}&limit=${limit}&orderBy=${orderBy}&orderType=${orderType}` : ''}`, signal) as
            Promise<ServiceDataResponse<PaginatedList<Product>>>;
    }

    static getReviewedProductsForUser(signal?: AbortSignal, offset?: number, limit?: number, orderBy?: string, orderType?: string) {
        return this.get(`api/product/reviewed${!isNaN(offset) && !isNaN(limit) && orderBy && orderType ? `?offset=${offset}&limit=${limit}&orderBy=${orderBy}&orderType=${orderType}` : ''}`, signal) as
            Promise<ServiceDataResponse<PaginatedList<Product>>>;
    }

    static getFavouriteProducts(machineCollectionId: string) {
        return this.get(`api/product/favourites/${machineCollectionId}`) as Promise<ServiceDataResponse<Product[]>>;
    }

    static saveProduct(product: ProductCreation, isIncomplete = false) {
        return this.post(`api/product?incomplete=${isIncomplete}`, product) as Promise<ServiceDataResponse<Product>>;
    }

    static approveProduct(token: string, comment: string) {
        return this.put(`api/product/approval?token=${token}`, comment) as Promise<ServiceResponse>;
    }
    
    static updateProduct(product: ProductCreation, isIncomplete = false) {
        return this.put(`api/product?incomplete=${isIncomplete}`, product) as Promise<ServiceDataResponse<Product>>;
    }

    static updateDigitalTwinOnProduct(productId: string, enableDigitalTwin: boolean) {
        return this.put(`api/product/digitaltwin?enableDigitalTwin=${enableDigitalTwin}`, productId) as Promise<ServiceDataResponse<Product>>;
    }

    static resetProduct(productId: string) {
        return this.put('api/product/status/reset', productId) as Promise<ServiceResponse>;
    }

    static rejectProduct(token: string, comment: string) {
        return this.put(`api/product/reject?token=${token}`, comment) as Promise<ServiceResponse>;
    }

    static releaseProduct(product: ProductCreation, isIncomplete = false) {
        return this.post(`api/product/status/release?incomplete=${isIncomplete}`, product) as Promise<ServiceResponse>;
    }

    static createAndReleaseProduct(product: ProductCreation, isIncomplete = false) {
        return this.post(`api/product/createandrelease?incomplete=${isIncomplete}`, product) as Promise<ServiceResponse>;
    }

    static reviewProduct(productReview: ProductReview) {
        return this.post('api/product/status/review', productReview) as Promise<ServiceResponse>;
    }
    
    static resendProductEmails (id: string) {
        return this.post(`api/product/${id}/email`) as Promise<ServiceResponse>;
    }

    static copyProduct(id: string) {
        return this.post(`api/product/${id}/copy`) as Promise<ServiceResponse>;
    }

    static addProductToFavourites(machineCollectionId: string, productId: string) {
        return this.post(`api/product/${productId}/favourites/${machineCollectionId}`) as Promise<ServiceResponse>;
    }

    static deleteProduct(id: string) {
        return this.delete(`api/product/${id}`) as Promise<ServiceResponse>;
    }

    static cancelProductRequests() {
        return this.delete('api/product/cancel') as Promise<ServiceResponse>;
    }

    static removeProductFromFavourites(machineCollectionId: string, productId: string) {
        return this.delete(`api/product/${productId}/favourites/${machineCollectionId}`) as Promise<ServiceResponse>;
    }

    static getCategories() {
        return this.get('api/category') as Promise<ServiceDataResponse<Categories>>;
    }

    static getCategoriesWithUsage() {
        return this.get('api/category/withusage') as Promise<ServiceDataResponse<Categories>>;
    }

    static saveCategory(categoryCreation: CategoryCreation) {
        return this.post('api/category', categoryCreation) as Promise<ServiceResponse>;
    }

    static saveCategorySubgroup(categoryCreation: CategoryCreation) {
        return this.post('api/category/subgroup', categoryCreation) as Promise<ServiceResponse>;
    }

    static updateCategory(content: Content, obsolete = false) {
        return this.put(`api/category?obsolete=${obsolete ? 'true' : 'false'}`, content) as Promise<ServiceResponse>;
    }

    static updateCategorySubgroup(content: Content) {
        return this.put('api/category/subgroup', content) as Promise<ServiceResponse>;
    }

    static deleteCategory(id: number) {
        return this.delete(`api/category/${id}`) as Promise<ServiceResponse>;
    }

    static deleteCategorySubgroup(id: number) {
        return this.delete(`api/category/subgroup/${id}`) as Promise<ServiceResponse>;
    }

    static saveImage(folder: string, image: FormData) {
        return this.post(`api/image/${folder}`, image, false, null) as Promise<ServiceDataResponse<string>>;
    }

    static getUsers(signal?: AbortSignal, offset?: number, limit?: number, orderBy?: string, orderType?: string) {
        return this.get(`api/user${!isNaN(offset) && !isNaN(limit) && orderBy && orderType ? `?offset=${offset}&limit=${limit}&orderBy=${orderBy}&orderType=${orderType}` : ''}`, signal) as
            Promise<ServiceDataResponse<PaginatedList<User>>>;
    }

    static getUser(id: string) {
        return this.get(`api/user/${id}`) as Promise<ServiceDataResponse<User>>;
    }

    static getCreatorUser(productId: string) {
        return this.post(`api/user/${productId}`) as Promise<ServiceDataResponse<User>>;
    }

    static updateUser(user: User) {
        return this.put('api/user', user) as Promise<ServiceResponse>;
    }

    static cancelUserRequests() {
        return this.delete('api/user/cancel') as Promise<ServiceResponse>;
    }

    static getTags(signal?: AbortSignal) {
        return this.get('api/tag', signal) as Promise<ServiceDataResponse<Tag[]>>;
    }

    static createTag(tag: Tag) {
        return this.post('api/tag', tag) as Promise<ServiceResponse>;
    }

    static addTagToProduct(productId: string, tagId: string) {
        return this.post(`api/tag/${tagId}/addToProduct/${productId}`) as Promise<ServiceResponse>;
    }

    static updateTag(tag: Tag) {
        return this.put('api/tag', tag) as Promise<ServiceResponse>;
    }

    static cancelTagRequest() {
        return this.delete('api/tag/cancel') as Promise<ServiceResponse>;
    }

    static deleteTag(id: string) {
        return this.delete(`api/tag/${id}`) as Promise<ServiceResponse>;
    }

    static removeTagFromProduct(productId: string, tagId: string) {
        return this.delete(`api/tag/${tagId}/removeFromProduct/${productId}`) as Promise<ServiceResponse>;
    }
}
